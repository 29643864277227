import { API } from "aws-amplify";

import { APIEndpoints, APIPaths } from "../../../config/APIEndpoints";
import { initializeAPIOptions } from "../../../utility/API";
import { fetchAuthenticatedUser, setUserAttributes } from "./loginActions";
import { loaderActions } from "../";
import { LoaderContent } from "../../../utility/constants/LoaderContent";

export const UPDATE_PROFILE_PIC="UPDATE_PROFILE_PIC";
export const SET_USER_PERMISSIONS_BASED_ON_CLINIC = "SET_USER_PERMISSIONS_BASED_ON_CLINIC";


const updateProfilePic = (profilePicURL) => ({
    type: UPDATE_PROFILE_PIC,
    payload: profilePicURL,
})

const updateLoginedUserPermission = (permissions) =>({
    type: SET_USER_PERMISSIONS_BASED_ON_CLINIC,
    payload: permissions,
})

// imageContent in base64 format and assuming the content is PNG format
const profilePicUploadAPI = (imageData) => async dispatch =>{
    const { token } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.NHCUSERAPI.name;
    const apiPath = APIPaths.USER_PROFILE_PIC;
    const headers = {
        "Content-Type":"image/png"
    }
    const queryString = undefined;

    const apiOptions = initializeAPIOptions(token, imageData, queryString, headers);
    return await API.post(apiName, apiPath, apiOptions);
}

export const profilePicUpload = (imageData) => async dispatch => {

    try {

        const response = await dispatch(profilePicUploadAPI(imageData));
        if(response.error.code) {
            throw response.error
        }
        dispatch(updateProfilePic(response.data.url));

    } catch(error) {
        throw error;
    }
}


// Funtions to get the profile picture
const fetchProfilePicAPI = () => async dispatch => {
    const { token } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.NHCUSERAPI.name;
    const apiPath = APIPaths.USER_PROFILE_PIC;

    const apiOptions = initializeAPIOptions(token);
    return await API.get(apiName, apiPath, apiOptions);
}

export const fetchProfilePic = () => async dispatch => {

    try {

        const response = await dispatch(fetchProfilePicAPI());
        if(response.error.code) {
            throw response.error
        }
        dispatch(updateProfilePic(response.data.url));

    } catch(_error) {
        dispatch(updateProfilePic(""));
    }
}

const updateProfileAttributesAPI = (given_name, family_name, phone_number, address) => async dispatch => {

    const { token } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.NHCUSERAPI.name;
    const apiPath = APIPaths.USER_DETAILS;

    const body = {
        first_name: given_name,
        last_name: family_name,
        phone_number,
        address
    }

    const apiOptions = initializeAPIOptions(token, body);
    return await API.put(apiName, apiPath, apiOptions);
}

export const updateProfileAttributes = (given_name, family_name, phone_number, address) => async dispatch => {

    try {

        dispatch(loaderActions.startAppLoader(LoaderContent.UPDATE_PROFILE));

        await dispatch(updateProfileAttributesAPI(given_name, family_name, phone_number, address));
        // if(response.error.code) {
        //     throw response.error
        // }
        dispatch(setUserAttributes({ 
            firstName:  given_name, 
            lastName: family_name, 
            phoneNumber: phone_number,
            address,
          }));
        dispatch(loaderActions.stopAppLoader());
    } catch(error) {
        dispatch(loaderActions.stopAppLoader());
        throw error;
    }

}

// ******************************************
// Set allowed user permissions for various clinic
// ******************************************

const fetchUserPermissionsApi = () => async dispatch =>{
    const { token } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.NHCUSERACCESSAPI.name
    const apiPath = APIPaths.CLINIC_BASED_PERMISSIONS

    const apiOptions = initializeAPIOptions(token, undefined, undefined, undefined);
    return await API.get(apiName, apiPath, apiOptions);
  
}

export const fetchUserPermissions = () => async dispatch =>{
    try{
        const response = await dispatch(fetchUserPermissionsApi());
        dispatch(updateLoginedUserPermission(response.data));
    }catch(error){
        throw error;
    }
}
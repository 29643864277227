import React, { useState } from 'react';

import { withStyles } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Error";

import {useStyles} from "./styles";

const VersionDetail = React.memo(({ classes }) => {

	const [updatedVersion] = useState(false);
	
	return (
		<div className={classes.versionDetailsContainer}>
		{
			updatedVersion ?
				<div className={classes.versionDetails}>
				<WarningIcon />
				<p>New Version Available: <span>Click here to update.</span></p>
			</div>
			:
		<span>On version: {process.env.REACT_APP_VERSION}</span>
		}
		</div>
	)
})

export default withStyles(useStyles)(VersionDetail);
export const useStyles = MUITheme => ({
	plainHeaderContainer:{
		height: 60,
		padding: '0 8%',
		alignItems: 'center',
		'& h1':{ 
			margin: 0,
			lineHeight: 0,
			[MUITheme.breakpoints.down("xs")]: { paddingBottom: 15 }
		},
		'& a':{ display: 'inline-block' },
		'& img':{ maxHeight: 55 },
		[MUITheme.breakpoints.down("md")]: { padding: '0 25px' },
		[MUITheme.breakpoints.down("sm")]: { padding: '0 25px' },
		[MUITheme.breakpoints.down("xs")]: { padding: '10px 25px', height:"auto" }
	},
	btnContainer:{
		[MUITheme.breakpoints.down("xs")]: { textAlign: 'left' }
	}	
})
import React from "react";
import { Link } from  "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import logo from "../../../assets/images/logo.png";

import { useStyles } from "./styles";

const WhiteBgLogo =  React.memo(({classes})  => {
  return (
    <div className={classes.whiteBgLogoContainer}>
      <h1>
        <Link to="/">
          <img src={logo} alt="Niram Healthcare" />
        </Link>
      </h1>
    </div>
  );
});

export default withStyles(useStyles)(WhiteBgLogo);

import MuiButton from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const Button = withStyles(MUITheme => ({
  root: props => {
    const rootStyles = {
      boxShadow: 'none',
      fontFamily: MUITheme.typography.fontFamily.primary,
      fontWeight: 'bold',
      fontSize: 18,
      padding: "7px 38px",
      lineHeight: "24px",
      type : 'button',
      variant : 'text',
    };
    if(props.color === "primary") {
      rootStyles.color = MUITheme.palette.text.white.primary;
      rootStyles.backgroundColor = '#259BEB';
      rootStyles.border = "1px solid #136BD6";
      rootStyles.borderRadius = '4px';
      rootStyles["&:hover"] = {
        backgroundColor: '#fff',
        color: '#259BEB',
        boxShadow: 'none'
      };
    }
    if (props.color === "primary" && props.variant === "outlined") {
      rootStyles.color = MUITheme.palette.text.blue.primary;
      rootStyles.backgroundColor = '#E2F3FA';
      rootStyles.border = "1px solid #144DB0";
    }
     if (props.color === "primary" && props.variant === "text") {
      rootStyles.color = '#259BEB';
      rootStyles.backgroundColor = 'transparent';
      rootStyles.border = "1px solid transparent";
      rootStyles["&:hover"] = {
        border: "1px solid #259BEB"
      };
    }
    if (props.variant === "outlined" && props.size==="small") {
      rootStyles.color = '#259BEB';
      rootStyles.backgroundColor = 'transparent';
      rootStyles.border = "1px solid #259BEB";
      rootStyles.fontSize = '14px';
      rootStyles.padding = '3px 24px';
      rootStyles["&:hover"] = {
        color : '#ffff',
        backgroundColor : "#259BEB",
      };
    }
    // if (props.color === "primary" && props.variant === "textSmall") {
    //   rootStyles.color = '#259BEB';
    //   rootStyles.backgroundColor = 'transparent';
    //   rootStyles.border = "1px solid transparent";
    //   rootStyles.fontSize = '14px';
    //   rootStyles.padding = '3px 24px';
    // }
    // if(props.color === "primary" && props.variant === "small") {
    //   rootStyles.color = MUITheme.palette.text.white.primary;
    //   rootStyles.backgroundColor = '#259BEB';
    //   rootStyles.border = "1px solid #136BD6";
    //   rootStyles.borderRadius = '4px';
    //   rootStyles.fontSize = '14px';
    //   rootStyles.padding = '3px 24px';
    //   rootStyles["&:hover"] = {
    //     backgroundColor: '#fff',
    //     color: '#259BEB'
    //   };
    // }
    if (props.variant === "text" && props.size === "small") {
      rootStyles.color = '#259BEB';
      rootStyles.backgroundColor = 'transparent';
      rootStyles.border = "1px solid transparent";
      rootStyles.fontSize = '14px';
      rootStyles.padding = '3px 24px';
    }
    if (props.variant === "outlined" && props.size === "small") {
      rootStyles.color = '#259BEB';
      rootStyles.backgroundColor = 'transparent';
      rootStyles.border = "1px solid #136BD6";
      rootStyles.fontSize = '14px';
      rootStyles.padding = '3px 24px';
    }
    if(props.color === "primary" && props.size === "small") {
      rootStyles.color = MUITheme.palette.text.white.primary;
      rootStyles.backgroundColor = '#259BEB';
      rootStyles.border = "1px solid #136BD6";
      rootStyles.borderRadius = '4px';
      rootStyles.fontSize = '14px';
      rootStyles.padding = '3px 24px';
      rootStyles["&:hover"] = {
        backgroundColor: '#fff',
        color: '#259BEB'
      };
    }
    return rootStyles;
  },
}))(MuiButton);

export default Button;
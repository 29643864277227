import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

import Button from "../../common/Button";
import {GlobalRoutes} from "../../../GlobalRouter/Routes";

const LoggedOutActions = () => {

  const history = useHistory();

  return (
    <Fragment>
      <Button 
        children="login" 
        color="primary" 
        variant="contained"
        onClick={() => history.push(GlobalRoutes.LOGIN.path)} />
    </Fragment>
  );
};

export default LoggedOutActions;

import { lazy } from "react";
import store from "../Redux/Store";

import withRegistrationHeader from "../components/HOC/withRegistrationHeader";
import withHeaderAndFooter from "../components/HOC/withHeaderAndFooter";
import withPlainHeader from "../components/HOC/withPlainHeader";
//import withLeftNavHeader from "../components/HOC/withLeftNavHeader";
import withWhiteBgLogo from "../components/HOC/withWhiteBgLogo";

const Login = lazy(() => import("../components/Authentication/Login"));
const Signup = lazy(() => import("../components/Authentication/Signup"));
const SignUpConfirm = lazy(() => import("../components/Authentication/SignupConfirm"));
const ForgotPassword = lazy(() => import("../components/Authentication/ForgotPassword"));
const ForgotPasswordConfirm = lazy(() => import("../components/Authentication/ForgotPasswordConfirm"))
const ChangePassword = lazy(() => import("../components/Authentication/ChangePassword"))
const TermsConditions = lazy(() => import("../components/Authentication/TermsConditions"))
const Home = lazy(() => import("../components/Home"));

const Dashboard = lazy(() => import("../components/Dashboard"));

const LeadGeneration = lazy(() => import("../components/LeadGeneration"));
const UserProfile = lazy(() => import("../components/UserProfile"));

const ADTAutomation = lazy(() => import("../components/Services/ADTAutomation"));
const ADTAutomationHistory = lazy(() => import("../components/Services/ADTAutomation/UploadFileHistory"));

const Settings = lazy(() => import("../components/Settings"));

const Patient360 = lazy(() => import("../components/Services/Patient360"));

const PatientTrial = lazy(() => import("../components/Services/PatientTrial"));

// const SelfServiceDashboard = lazy(() => import("../components/NLP"));

//const LOGIN_PATH = "/login";

const LoginComponent = withRegistrationHeader(Login);
const SignupComponent = withRegistrationHeader(Signup);
const SignUpConfirmComponent = withRegistrationHeader(SignUpConfirm);
const ForgotPasswordComponent = withRegistrationHeader(ForgotPassword);
const ForgotPasswordConfirmComponent = withRegistrationHeader(ForgotPasswordConfirm);
const ChangePasswordComponent = withRegistrationHeader(ChangePassword);
const TermsAndConditionsComponent = withRegistrationHeader(TermsConditions)

const HomeComponent = withPlainHeader(Home);

const DashboardComponent = withHeaderAndFooter(Dashboard);
const UserProfileComponent = withHeaderAndFooter(UserProfile);

const LeadGenerationComponent = withWhiteBgLogo(LeadGeneration);
const ADTAutomationComponent = withHeaderAndFooter(ADTAutomation)
const ADTAutomationHistoryComponent = withHeaderAndFooter(ADTAutomationHistory)
const Patient360Component = withHeaderAndFooter(Patient360);
const PatientTrialComponent = withHeaderAndFooter(PatientTrial);

const SettingsComponent = withHeaderAndFooter(Settings);
// const SelfServiceDashboardComponent = withHeaderAndFooter(SelfServiceDashboard);

export const GlobalRoutes = {
  LOGIN: {
    name: "login",
    path: "/login",
    component: LoginComponent,
  },
  SIGNUP: {
    name: "signup",
    path: "/signup",
    component: SignupComponent,
  },
  SIGNUP_CONFIRM: {
    name: "signup confirm",
    path: "/signupconfirmation",
    component: SignUpConfirmComponent,
  },
  FORGOT_PASSWORD: {
    name: "forgot password",
    path: "/forgotpassword",
    component: ForgotPasswordComponent,
  },
  FORGOT_PASSWORD_CONFIRM: {
    name: "forgot password confirmation",
    path: "/forgotpasswordconfirm",
    component: ForgotPasswordConfirmComponent,
  },
  CHANGE_PASSWORD: {
    name: "change password",
    path: "/changepassword",
    component: ChangePasswordComponent,
  },
  TERMS_AND_CONDITIONS: {
    name: "terms and conditions",
    path: "/termsandconditions",
    component: TermsAndConditionsComponent,
  },
  LEAD_GENERATION: {
    name: "lead generation",
    path: "/leadgeneration",
    component: LeadGenerationComponent,
  },
  HOME: {
    name: "home",
    path: "/home",
    component: HomeComponent,
  },
  LANDING: {
    name: "landing",
    path: "/mydashboard",
    component: DashboardComponent,
  },
  DASHBOARD: {
    name: "my dashboard",
    path: "/mydashboard/:dashboardId",
    component: DashboardComponent,
  },
  USER_PROFILE: {
    name: "user profile",
    path: "/userprofile",
    component: UserProfileComponent,
  },
  ADT_AUTOMATION:{
    name: "adt automation",
    path: "/adtautomation",
    component: ADTAutomationComponent
  },
  ADT_AUTOMATION_HISTORY:{
    name: "adt automation history",
    path: "/adtautomationhistory",
    component: ADTAutomationHistoryComponent
  },
  SETTINGS: {
    name: "settings",
    path: "/settings",
    component: SettingsComponent,
  },
  PATIENT360: {
    name: "patient360",
    path: "/patient360",
    component: Patient360Component,
  },
  PATIENTTRIAL: {
    name: "patienttrial",
    path: "/patienttrial",
    component: PatientTrialComponent,
  },
  TRIAL_VIEW: {
    name: "patienttrial view",
    path: "/patienttrial/:viewId",
    component: PatientTrialComponent,
  },
  VIEW: {
    name: "patient view",
    path: "/patient360/:view_id",
    component: Patient360Component,
  },
  // SELF_SERVICE_DASHBOARD: {
  //   name: "self_service_dashboard",
  //   path: "/selfServiceDashboard",
  //   component: SelfServiceDashboardComponent
  // }

};

// ACCEPT_AGREEMENT: {
//   name: "acceptagreement",
//   path: "/acceptagreement",
//   component: AcceptAgreementComponent,
// },

export const setupRouteAuthentications = () => {
  const state = store.getState();
  const { isLoggedIn } = state.userReducer;

  return {
    ...GlobalRoutes,
    LANDING: {
      ...GlobalRoutes.LANDING,
      isAllowed: isLoggedIn,
      redirectTo: GlobalRoutes.LOGIN.path,
    },
    CHANGE_PASSWORD: {
      ...GlobalRoutes.CHANGE_PASSWORD,
      isAllowed: isLoggedIn,
      redirectTo: GlobalRoutes.LOGIN.path,
    },
    USER_PROFILE: {
      ...GlobalRoutes.USER_PROFILE,
      isAllowed: isLoggedIn,
      redirectTo: GlobalRoutes.LOGIN.path,
    },
    LEAD_GENERATION: {
      ...GlobalRoutes.LEAD_GENERATION,
      isAllowed: isLoggedIn,
      redirectTo: GlobalRoutes.LOGIN.path,
    },
    DASHBOARD: {
      ...GlobalRoutes.DASHBOARD,
      isAllowed: isLoggedIn,
      redirectTo: GlobalRoutes.LOGIN.path,
    },
    ADT_AUTOMATION: {
      ...GlobalRoutes.ADT_AUTOMATION,
      isAllowed: isLoggedIn,
      redirectTo: GlobalRoutes.LOGIN.path,
    },
    ADT_AUTOMATION_HISTORY: {
      ...GlobalRoutes.ADT_AUTOMATION_HISTORY,
      isAllowed: isLoggedIn,
      redirectTo: GlobalRoutes.LOGIN.path,
    },
    SETTINGS: {
      ...GlobalRoutes.SETTINGS,
      isAllowed: isLoggedIn,
      redirectTo: GlobalRoutes.LOGIN.path,
    },
    PATIENT360: {
      ...GlobalRoutes.PATIENT360,
      isAllowed: isLoggedIn,
      redirectTo: GlobalRoutes.LOGIN.path
    },
    PATIENTTRIAL: {
      ...GlobalRoutes.PATIENTTRIAL,
      isAllowed: isLoggedIn,
      redirectTo: GlobalRoutes.LOGIN.path
    },
    VIEW: {
      ...GlobalRoutes.VIEW,
      isAllowed: isLoggedIn,
      redirectTo: GlobalRoutes.LOGIN.path
    },
    // SELF_SERVICE_DASHBOARD: {
    //   ...GlobalRoutes.SELF_SERVICE_DASHBOARD,
    //   isAllowed: isLoggedIn,
    //   redirectTo: GlobalRoutes.LOGIN.path
    // }
  };
};

// TBD - Improper configuration effected the Page Not Found Routes
/*
ACCEPT_AGREEMENT: {
  ...GlobalRoutes.ACCEPT_AGREEMENT,
  isAllowed: isLoggedIn,
  redirectTo: GlobalRoutes.LOGIN.path,
},

HELP_CENTER: {
  ...GlobalRoutes.HELP_CENTER,
  isAllowed: isLoggedIn,
  redirectTo: GlobalRoutes.LOGIN.path,
},

*/
import {clinicActions} from '../actionCreators';

const InitialPatientDetails={
    userClinics: [],
    selectedClinicId: "",
    clinicLocations: [],
    clinicDashboards: {},
    userInvitations: [],
    clinicUsers: [],
    clinicUsersMetrics: {
        total_entries: 0,
        expired_invites: 0,
        pending_invites: 0,
        cancelled_invites: 0,
        active_users: 0,
        deactivated_users: 0
    },
    clinicAccessRoles: [],
    platformPermissions: [],
    clinicAllRoles: [],
    dataImportHistoryList:[]
}

const clinicReducer=(state=InitialPatientDetails, action)=>{
    switch(action.type){
        case clinicActions.UPDATE_USER_CLINICS : {
            return {...state, userClinics : action.payload }
        }
        case clinicActions.UPDATE_USER_SELECTED_CLINIC : {
            return {...state, selectedClinicId : action.payload }
        }
        case clinicActions.UPDATE_CLINIC_LOCATIONS : {
            return {...state, clinicLocations : action.payload }
        }
        case clinicActions.UPDATE_DASHBOARDS_LIST : {
            return {...state, clinicDashboards : {...state.clinicDashboards, ...action.payload } }
        }
        case clinicActions.UPDATE_USER_INVITATIONS : {
            return {...state, userInvitations : action.payload }
        }
        case clinicActions.UPDATE_CLINIC_USERS : {
            return {...state, clinicUsers : action.payload }
        }
        case clinicActions.UPDATE_CLINIC_USER_METRICS : {
            return {...state, clinicUsersMetrics : action.payload }
        }
        case clinicActions.UPDATE_CLINIC_LOGO : {
            //return { ...state, userClinics: { ...state.userClinics, logo: action.payload } };
            return{...state, userClinics:[
                ...state.userClinics.map((item) => {
                    if(item.clinic_id === action.payload.clinicId) {
                      return {
                        ...item,  
                        logo: action.payload.logo
                      }
                    }
                    return item;
                  })
            ]}  
        }
        case clinicActions.UPDATE_CLINIC_ACCESS_ROLES : {
            return {...state, clinicAccessRoles : action.payload }
        }
        case clinicActions.UPDATE_PLATFORM_PERMISSIONS : {
            return {...state, platformPermissions : action.payload }
        }
        case clinicActions.UPDATE_CLINIC_ALL_ROLES : {
            return {...state, clinicAllRoles : action.payload }
        }
        case clinicActions.DATA_IMPORT_HISTORY_LIST : {
            return {...state, dataImportHistoryList : action.payload }
        }
        default:{
            return state
        }
    }
}

export default clinicReducer;
import React, { Fragment } from "react";

import PlainHeader from "../Header/PlainHeader";

import Announcement from "../Header/Announcement";
//import Footer from "../common/Footer";

const withPlainHeader = (Component) => {
  return props => (
    <Fragment>
    	<Announcement />
      <PlainHeader />
      <Component {...props} />
      {/* <Footer /> */}
    </Fragment>
  );
};

export default withPlainHeader;

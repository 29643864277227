import React from "react";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import logo from "../../../assets/images/logo.png";
import { useStyles } from "./styles";

const RegistrationHeader = React.memo(({ classes}) => {
  return (
    <Grid container className={classes.registrationHeaderWrapper}>
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.logoContainer}>
        <h1>
          <Link to="/">
            <img src={logo} alt="Niram Healthcare" />
          </Link>
        </h1>
      </Grid>
    </Grid>
  );
});

export default withStyles(useStyles)(RegistrationHeader);


import {patientTrialActions} from '../actionCreators';

const IntitialState = {
    filterMetadata:{},
    filters:{},
    filterValues:{},
    patientReport:{},
    trialViewList:[]
}

//Merge patient details based on patient account number

const updateFilterMetadata = (filterAttributes) =>{
    const metadata = {};
    if(filterAttributes){
        const attributes = {};
        filterAttributes.filter(f=>f.filterable_flg).forEach(f=>(attributes[f.es_field_name]={field:f.es_field_name,dataType:f.datatype,label:f.display_name,multiValue:f.multi_value_flg,multiField:f.multi_field_flg,...f.additional_details}))
        Object.keys(attributes).length> 0 && (metadata.filterAttributes = attributes);
    }
    return metadata;
}

const patientTrialReducer = (state = IntitialState, action) =>{
    switch(action.type){     
        case patientTrialActions.TRIAL_VIEW_LIST:{
            return{...state, trialViewList:action.payload};
        }   
        case patientTrialActions.FILTER_META_DATA:{
            return{...state, filterMetadata:updateFilterMetadata(action.payload)};
        }
        case patientTrialActions.FILTER_VALUES:{
            return{...state, filterValues:{...state.filterValues,...action.payload}};
        }
        case patientTrialActions.PATIENT_TRIAL_REPORT:{
            return{...state, patientReport:action.payload};
        }
        case patientTrialActions.EXPORT_FILE_CONTENT:{
            return{...state, file: action.payload}
        }
        default : {
            return state;
        }
    }

}
export default patientTrialReducer;
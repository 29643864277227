const FORM_DATA = 'form-data';
const METHOD_GET = 'GET';
const METHOD_POST = 'POST';
const METHOD_PUT = 'PUT';
const METHOD_DELETE = 'DELETE';
const defaultOpt = {
  method:METHOD_GET,
  mode: "cors",
  credentials: "include",
  headers: {
      "Content-Type": "application/json; charset=utf-8"
  }
}

const defaultPostOpt = {
    ...defaultOpt,
    method: METHOD_POST
}

const defaultFormDataOpt = {
  ...defaultPostOpt,
  bodyType:FORM_DATA,
  headers: {
    "Content-Type": ""
  }
}
const mergeOptions = (dOpt,options)=>
{
  return {...dOpt,...options,headers:{...dOpt.headers,...(options.headers || {})}};
}

export class CustomAPI {

    static async delete(url,{params={},headers={},options={}}){
      //options = {...options,method:METHOD_DELETE};
      return CustomAPI.get(url,{params,headers,options:{...mergeOptions(defaultOpt,options),method:METHOD_DELETE}});
    }

    static async formData(url,{params={},body={},headers={},options={}}){
      //options = {...options,method:METHOD_POST};
      return CustomAPI.post(url,{params,headers,body,options:{...mergeOptions(defaultFormDataOpt,options),method:METHOD_POST}});
    }

    static async get(url,{params={},headers={},options={}}){
      return await CustomAPI._send(url,{params,headers,options:{...mergeOptions(defaultOpt,options)}});
    }

    static async put(url,{params={},body={},headers={},options={}}){
      options = {...options,method:METHOD_PUT};
      return CustomAPI.post(url,{params,body,headers,options:{...options,method:METHOD_PUT}});
    }
    
    static async post(url,{params={},body={},headers={},options={}}){
        return await CustomAPI._send(url,{params,body,headers,options:{...mergeOptions(defaultPostOpt,options)}});;
    }
    
    static async _send(url,{params,headers,body,options={method:METHOD_GET,mode: "cors",}}){
        let paramsToBody = options.method == METHOD_POST || options.method == METHOD_PUT;
        params = params || {};
        if(options.bodyType == FORM_DATA){
          delete options.headers['Content-Type'];
          const fields = options.fields || Object.keys(body);
          options.fields && (delete options.fields);
          let formData = new FormData();
          fields.forEach(k=>{
            formData.append(k,body[k]);
          })
          options['body'] = formData;
        } else if(paramsToBody){
          options['body'] = JSON.stringify(body);
        }

        options.headers = {...options.headers,...headers};

        const qparam = Object.keys(params).map(key => {
          let v = params[key];
          return v!=undefined? (key+'='+v):'';
        }).filter(x => x.length > 0).join('&');

        url = qparam.length?((url.includes('?')?'&':'?') + qparam):url;


        let data = await (await (fetch(url,options)
        .then(res => {
          let contentType = res.headers.get("content-type");
          let jres = res;
          if(contentType && contentType.includes("application/json")) {
            jres = res.json();
          }
          else if(res.text){
            jres = res.text();
          }          
          
          if(res.ok){
            return jres;            
          }
          else{
            //return jres.then(err => {throw err;});
            return jres.then(Promise.reject.bind(Promise));
          }
          
        })
        .catch(err => {
          console.log('Error in data service: ', err);
          throw err;
        })
        .finally(()=>{
        })
      ))
      return data
    }
}
import { leadGenActions } from "../actionCreators";

const InitialLoaderState = {
  emrs: [],
  services: {
    serviceCareGapClosure: false,
    serviceTargetedInvestments: false,
    serviceQualityPerformanceMetrics: false,
    serviceEncounterDetailsAndAlerts: false,
    serviceChronicPatients: false,
  },
  clinicInfo: {
    practiceName: "",                                             
    numOfClinics: "", 
    typeOfClinic: [],
  },
  contact: {
    address:"",
    city:"",
    state:"",
    zipcode:"",
    }
};

const leadGenReducer = (state = InitialLoaderState, action) => {
  switch (action.type) {
    case leadGenActions.UPDATE_LEAD_GEN_EMRS: {
        return { ...state, emrs: action.payload };
    }
    case leadGenActions.UPDATE_LEAD_GEN_SERVICES: {
        return { ...state, services: action.payload };
    }
    case leadGenActions.UPDATE_LEAD_GEN_CONTACT_INFO:{
      return {...state, contact:action.payload};
    }
    case leadGenActions.UPDATE_LEAD_GEN_CLINIC_INFO: {
        return { ...state, clinicInfo: action.payload };
      }
    default: {
      return state;
    }
  }
};



export default leadGenReducer;

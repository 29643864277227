import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';


import logo from "../../../assets/images/logo.png";
import NavigationBar from "./NavigationBar";
import LoggedInActions from "./LoggedInActions";
import LoggedOutActions from "./LoggedOutActions";
import { useStyles } from "./styles";

const stateSelector = state => ({
  userClinics: state.clinicReducer.userClinics,
  selectedClinicId: state.clinicReducer.selectedClinicId,
  isLoggedIn: state.userReducer.isLoggedIn
});


const AppHeader = ({ classes }) => {
  //const { isLoggedIn } = useSelector(state => state.userReducer);
  const { isLoggedIn, selectedClinicId, userClinics } = useSelector(state => stateSelector(state));
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const getSelectedClinicLogoUrl = () => {

    if(selectedClinicId !== "" && !isEmpty(userClinics)) {
      // console.log(selectedClinicId, userClinics);

      let clinicLogoUrlArray = userClinics.filter(c => c.clinic_id === selectedClinicId)
      let clinicLogoUrl = clinicLogoUrlArray[0] !== undefined ? clinicLogoUrlArray[0].logo : "";
      return clinicLogoUrl;

    } else {
      return null;
    }

  }

  const setDefaultLog = (event) => {
    event.target.src = logo;
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };


  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    ><NavigationBar/></Menu>
  )
  return (
    <AppBar className={classes.AppHeader}>
      <Toolbar className={classes.toolBar}>
        <div className={classes.logoNavContainer}>
        <h1>
          <Link to="/">
            <img src={ getSelectedClinicLogoUrl() || logo} onError={e => setDefaultLog(e)} alt="Niman Healthcare" />
          </Link>
        </h1>
      </div>
      <div className={classes.sectionDesktop}>
        <NavigationBar />
      </div>
      <div className={classes.dropDownUserActionsContainer}>
        {
          isLoggedIn ? <LoggedInActions /> : <LoggedOutActions />
        }
        <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
      </div>
      </Toolbar>
      {renderMobileMenu}
    </AppBar>
  );
};

export default withStyles(useStyles)(AppHeader);

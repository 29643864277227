export const useStyles = _MUITheme => ({
	whiteBgLogoContainer:{
		height: 60,
		padding: '0 20px',
		'& h1':{ 
			margin: 0,
			lineHeight: 0
		},
		'& img':{
			maxHeight: 55
		}
	}
})
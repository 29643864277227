import {payersAndMeasuresActions} from '../actionCreators'

const InitialState={
    typeOfPayers: [
        {
            payerId: 0,
            payerName:"All Payers",
            payerStatus:true
        }
    ],
    measuresDetails: [{
        measureId : null,
        measureKey: null,
        measureName: null,
        value: null,
        status: null
    }],
    yearsList: [parseInt(new Date().getFullYear())],
    activePayersAndMeasures: [],
    flag: true,
}

const payersAndMeasuresReducer = (state = InitialState, action) => {
    switch (action.type) {
        case payersAndMeasuresActions.UPDATE_ACTIVE_PAYERS_AND_MEASURES:{
            return {...state, activePayersAndMeasures: action.payload}
        }
        case payersAndMeasuresActions.UPDATE_TYPE_OF_PAYER: {
            return {...state, typeOfPayers: action.payload};
        }
        case payersAndMeasuresActions.UPDATE_MEASURES_DETAILS: {
            return {...state, measuresDetails: action.payload};
        }
        case payersAndMeasuresActions.UPDATE_YEARS_LIST: {
            return{...state, yearsList: action.payload};
        }
        case payersAndMeasuresActions.UPDATE_FLAG: {
            return{...state, flag: action.payload};
        }
        case payersAndMeasuresActions.UPDATE_MEASURE_VALUE:{
            return{...state, measuresDetails:[
                ...state.measuresDetails.map((item) => {
                    // Find the item with the matching measureId
                    if(item.measureId === action.payload.measureId) {
                      // Return a new object
                      return {
                        ...item,  // copy the existing item
                        value: action.payload.value// replace the value
                      }
                    }
                    // Leave every other item unchanged
                    return item;
                  })
            ]}  
        }
        case payersAndMeasuresActions.UPDATE_IS_ACTIVE:{
            return{...state, measuresDetails:[
                ...state.measuresDetails.map((item) => {
                    if(item.measureId === action.payload.measureId){
                        return{
                            ...item,
                            status: action.payload.status
                        }
                    }
                    return item;
                }
                )
            ]
            }
        }
        default: {
            return state;
        }
    }
}
export default payersAndMeasuresReducer;
import React, {useState}  from "react";

import AppHeader from "../Header/AppHeader";
import LeftMenu from '../LeftMenu'
import {useStyles} from "./styles";

import Announcement from "../../components/Header/Announcement";

import Grid from '@material-ui/core/Grid';

const LeftMenuAndFooter = (props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState(true);

  const handleIconClick = () => {
    setIsOpen(!isOpen);
  }

  const handleClick = () => {
    setOpen(!open);
  };


  let wrapperClassName = classes.AdminWraper;

  if(!isOpen){
    wrapperClassName = `${classes.AdminWraper} ${classes.activeClass}`;
  }

  return (
    <>
      <AppHeader
        handleIconClick={handleIconClick}
        isTopMenuFullWidth={isOpen}
      />
      <Announcement />
      <Grid className={wrapperClassName} >
        <LeftMenu
          handleClick={handleClick}
          open={open}
          isLeftFullwidth={!isOpen}
        />
        <div className={classes.AdminUser}>
          <div className={classes.dashboardsMainParent}>
            {props.children}
          </div>
      </div>
    </Grid>   
    </>
  );
}

export default LeftMenuAndFooter

import React, { Fragment } from "react";
import RegistrationHeader from "../Header/RegistrationHeader";

const withRegistrationHeader = (Component) => {
  const childComponent =  props => (
	  <Fragment>
  	  <RegistrationHeader  />
    	<Component {...props} />
    </Fragment>  
  );
    return childComponent;
};

export default  withRegistrationHeader;

export const adtAutoNavItems = [
	{
		label: "Active Follow Up List",
		to: "/adtautomation",
		default: true
	},

]

/*
	{
		label: "Upload History",
		to: "/adtautomationhistory",
		default: false
	}
*/

export const dashboardNavItems = [
	{
		headerTitle: "Executive Dashboards",
		subHeader: [
			{
				label: "Operational Dashboard",
				linkTo: "/mydashboard/operational",
				default: false
			},
			{
				label: "Care Gap Identified",
				linkTo: "/mydashboard/templates",
				default: false
			},
			{
				label: "Care Gaps Closed",
				linkTo: "/mydashboard/caregapsclosed",
				default: false
			},
			{
				label: "Quality Dashboard",
				linkTo: "/mydashboard/quality",
				default: true
			},
			{
				label: "Quality Incentive Programs",
				linkTo: "/mydashboard/qualityincentive",
				default: false
			},
			{
				label: "Chronic Patients",
				linkTo: "/mydashboard/chronicpatients",
				default: false
			},
			{
				label: "Targeted Investment",
				linkTo: "/mydashboard/targetedinvestment",
				default: false
			},
			{
				label: "HIE Dashboard",
				linkTo: "/mydashboard/hie",
				default: false
			},
		]
	},
	{
		headerTitle: "Operational Analytics",
		subHeader:[
			{
				label: "Operational Report",
				linkTo: "/mydashboard/operationsreport",
				default: false
			},
		]
	},
	{
		headerTitle: "Quality Analytics",
		subHeader:[
			{
				label: "Quality Report",
				linkTo: "/mydashboard/qualityreport",
				default: false
			},
		]
	},
	{
		headerTitle: "Revenue Analytics",
		subHeader: [
			{
				label: "Incomplete Progress Notes",
				linkTo: "/mydashboard/claimsicpnreport",
				default: false
			},
			{
				label: "Encounters With No Claims",
				linkTo: "/mydashboard/claimsewncreport",
				default: false
			},
			{
				label: "HIE Alerts",
				linkTo: "/mydashboard/hiealertsreport",
				default: false
			},
		]
	}
	
]

/*
export const dashboardNavItems = [
	{
		headerTitle: "Executive Dashboard",
		subHeader: [
			{
				label: "Quality Dashboard",
				linkTo: "/mydashboard/quality",
				default: true
			},
			{
				label: "Operational Dashboard",
				linkTo: "/mydashboard/operational",
				default: false
			}
		]
	},
	{
		headerTitle: "Population Health Management",
		subHeader:[
			{
				label: "Care Gap Identified",
				linkTo: "/mydashboard/templates",
				default: false
			},
			{
				label: "Care Gaps Closed",
				linkTo: "/mydashboard/caregapsclosed",
				default: false
			},
			{
				label: "Chronic Patients",
				linkTo: "/mydashboard/chronicpatients",
				default: false
			}
		]
	},
	{
		headerTitle: "Revenue Growth Opportunities",
		subHeader: [
			{
				label: "Targeted Investment",
				linkTo: "/mydashboard/targetedinvestment",
				default: false
			},
			{
				label: "Quality Incentive Programs",
				linkTo: "/mydashboard/qualityincentive",
				default: false
			},
			{
				label: "HIE Dashboard",
				linkTo: "/mydashboard/hie",
				default: false
			}
		]
	}
	
]
*/

export const settingsNavItems = [
	{
		headerTitle: "Settings",
		subHeader: [
			{
				label: "Service Subscription",
				privilege:"SERVICE SUBSCRIPTION",
				linkTo: "/settings/servicesubscription",
				default: true
			},
			{
				label: "Payers and Measures",
				privilege:"PAYER MEASURE",
				linkTo: "/settings/payersandmeasures",
				default: false
			},
			{
				label: "Clinic Management",
				privilege:"CLINIC MANAGEMENT",
				linkTo: "/settings/clinicmanagement",
				default: false
			},
			{
				label: "Customize App",
				privilege:"APP CUSTOMIZATION",
				linkTo: "/settings/customizeapp",
				default: false
			},
			{
				label: "User Management",
				linkTo: "/settings/usermanagement",
				activeLinks:["/settings/usermanagement","/settings/userrolemanagement"],
				privilege:"USER MANAGEMENT", 
				default: false
			},	
			{
				label: "Data Import",
				linkTo: "/settings/dataimport",
				privilege:"DATA",
				default: false
			},				
			{
				label: "Help Center",
				linkTo: "/settings/helpcenter",
				default: false
			}	
		]
	},
]

export const Patient360NavItems = [
	{
		headerTitle: "Patient360",
		subHeader: [
			{	
				label: "Default View",
				linkTo: "/patient360",
				default: true
			},	

		]
	},
]



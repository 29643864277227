import { Auth } from "aws-amplify";
import { loaderActions } from "../";
import { LoaderContent } from "../../../utility/constants/LoaderContent";
import {clinicActions} from "../";
import {profilePicActions} from "./"
import {platformCoreActions} from "../"

export const SET_USER_LOGGED_IN = "SET_USER_LOGGED_IN";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_USER_EMAIL_VERIFIED = "SET_USER_EMAIL_VERIFIED";
export const SET_APP_INITIALIZED = "SET_APP_INITIALIZED";
export const RESET_USER_ON_SIGNOUT = "RESET_USER_ON_SIGNOUT";
export const SET_JWT_EXP = "SET_JWT_EXP";

export const SET_USER_ATTRIBUTES = "SET_USER_ATTRIBUTES";
export const SET_USER_FORCE_PASSWORD_CHANGE = "SET_USER_FORCE_PASSWORD_CHANGE";

const setUserLoggedIn = isLoggedin => ({ type: SET_USER_LOGGED_IN, payload: isLoggedin });

export const setUserEmail = email => ({ type: SET_USER_EMAIL, payload: email });

//const setUserEmailVerified = isEmailVerified => ({ type: SET_USER_EMAIL_VERIFIED, payload: isEmailVerified });

const setAppInitialized = isInitialized => ({ type: SET_APP_INITIALIZED, payload: isInitialized });

const resetUserOnSignout = () => ({ type: RESET_USER_ON_SIGNOUT });

const setJWTExp = exp => ({ type: SET_JWT_EXP, payload: exp });

const setForcePasswordChange = isForcePasswordChange => ({ type: SET_USER_FORCE_PASSWORD_CHANGE, payload: isForcePasswordChange });

// Function to get the User Role Associated with the Clinic Id
const getUserRole = (currentUser) => {
  let userRole = {}
  try {
    if(currentUser.signInUserSession.idToken.payload.tenant_role) {
      userRole = JSON.parse(currentUser.signInUserSession.idToken.payload.tenant_role);
    }
  } catch(error) {
    userRole = {}
  }
  return userRole;
}

export const fetchAuthenticatedUser = () => async (dispatch, getState) => {
  let bypassCache = false;

  const { exp } = getState().userReducer.jwt;
  const currentEpochInUTC = getCurrentUTCEpoch();
  if (!exp || currentEpochInUTC >= Number(exp)) {
    bypassCache = true;
  }

  const currentUser = await Auth.currentAuthenticatedUser({ bypassCache });
  const newExp = currentUser.signInUserSession.idToken.payload.exp;
  const userRole = getUserRole(currentUser);

  dispatch(setJWTExp(newExp));
  return {
    email: currentUser.attributes.email,
    isEmailVerified: currentUser.attributes.email_verified,
    firstName: currentUser.attributes.given_name, 
    lastName: currentUser.attributes.family_name,
    phoneNumber: currentUser.attributes.phone_number,
    address: currentUser.attributes.address,
    token: currentUser.signInUserSession.idToken.jwtToken,
    isFirstLogin: currentUser.attributes['custom:is_first_time_login']?currentUser.attributes['custom:is_first_time_login']:"1",
    userRole,
  };
};

export const initializeApplication = async dispatch => {
  try {

    // Check for the User Preference for RememberMe Selection
    if(localStorage.rememberMe === 'false') {
      dispatch(setAppInitialized(true));
      return;  
    }

    const userAttributes = await dispatch(fetchAuthenticatedUser());
    dispatch(setUserAttributes({...userAttributes, isLoggedIn:true}));
    dispatch(setAppInitialized(true));

    dispatch(profilePicActions.fetchUserPermissions());
    dispatch(clinicActions.fetchUserClinics());
    dispatch(profilePicActions.fetchProfilePic());
    dispatch(platformCoreActions.fetchAnnouncements());

  } catch (error) {
    dispatch(setAppInitialized(true));
  }
};

const loginSucess = loginResponse => async dispatch => {

  const userRole = getUserRole(loginResponse);

  const userAttributes = {
    isLoggedIn: true,
    email: loginResponse.attributes.email,
    isEmailVerified: loginResponse.attributes.email_verified,
    firstName: loginResponse.attributes.given_name, 
    lastName: loginResponse.attributes.family_name,
    phoneNumber: loginResponse.attributes.phone_number,
    address: loginResponse.attributes.address,
    isFirstLogin: loginResponse.attributes['custom:is_first_time_login']?loginResponse.attributes['custom:is_first_time_login']:"1",
    userRole,
  }

  return await Promise.all([
    dispatch(setUserAttributes(userAttributes)),
    dispatch(profilePicActions.fetchUserPermissions()),
    dispatch(clinicActions.fetchUserClinics()),
    dispatch(profilePicActions.fetchProfilePic()),
    dispatch(platformCoreActions.fetchAnnouncements()),
    dispatch(loaderActions.stopAppLoader()),
  ]);
};

const handleUserNotConfirmed = email => async dispatch => {
  return await Promise.all([dispatch(setUserLoggedIn(true)), dispatch(setUserEmail(email))]);
};

const handleForceChangePassword = (email, forcePasswordChangeFlag, loggedInFlag) => async dispatch => {
  return await Promise.all([dispatch(setForcePasswordChange(forcePasswordChangeFlag)), dispatch(setUserLoggedIn(loggedInFlag)), dispatch(setUserEmail(email))]);
};

export const login = (email, password) => async dispatch => {
  try {

    dispatch(loaderActions.startAppLoader(LoaderContent.LOGIN));
    const loginResponse = await Auth.signIn(email, password);

    // Check for the Change Password Challenge
    if(loginResponse.challengeName) {
      if(loginResponse.challengeName === "NEW_PASSWORD_REQUIRED") {
        dispatch(loaderActions.stopAppLoader());
        return await dispatch(handleForceChangePassword(email, true, true));
      }
    }
    return await dispatch(loginSucess(loginResponse));
  } catch (error) {
    dispatch(loaderActions.stopAppLoader());
    if (error.code === "UserNotConfirmedException") {
      await dispatch(handleUserNotConfirmed(email));
    }
    throw error;
  }
};

export const signout = async dispatch => {
  dispatch(loaderActions.startAppLoader(LoaderContent.SIGN_OUT));
  await Auth.signOut();
  await dispatch(resetUserOnSignout());
  dispatch(loaderActions.stopAppLoader());
};

// ********************************
// New functions are added
// ********************************

const getCurrentAuthenticatedUser = () => async (dispatch, getState) => {
  let bypassCache = false;

  const { exp } = getState().userReducer.jwt;
  const currentEpochInUTC = getCurrentUTCEpoch();
  if (!exp || currentEpochInUTC >= Number(exp)) {
    bypassCache = true;
  }

  const currentUser = await Auth.currentAuthenticatedUser({ bypassCache });
  const newExp = currentUser.signInUserSession.idToken.payload.exp;
  dispatch(setJWTExp(newExp));
  return currentUser;
};

export const setUserAttributes = (userAttributes) => dispatch => {
  dispatch({ type: SET_USER_ATTRIBUTES, 
    payload:  userAttributes
  });
}

export const updateUserAttributes = (given_name, family_name, phone_number, address) => async dispatch => {

  dispatch(loaderActions.startAppLoader(LoaderContent.UPDATE_PROFILE));
  //const user = await Auth.currentAuthenticatedUser({ bypassCache });
  const user = await dispatch(getCurrentAuthenticatedUser());

   await Auth.updateUserAttributes(user, {given_name, family_name, phone_number, address} )
  .then(_res => {
    dispatch(setUserAttributes({ 
      firstName:  given_name, 
      lastName: family_name, 
      phoneNumber: phone_number,
      address,
    }))
    dispatch(loaderActions.stopAppLoader())
  })
  .catch(err => {
    dispatch(loaderActions.stopAppLoader())
    throw err;
  })

}

export const updateFirstTimeUserAttribute = (isFirstLogin) => async dispatch => {
  const user = await dispatch(getCurrentAuthenticatedUser());
   await Auth.updateUserAttributes(user, {"custom:is_first_time_login": isFirstLogin} )
  .then(_res => {
    dispatch(setUserAttributes({ 
      isFirstLogin,
    }))
  })
  .catch(err => {
    throw err;
  })
}

export const forgotPassword = (email) => dispatch => {
  return Auth.forgotPassword(email)
  .then(() => {
    dispatch(setUserEmail(email));
  })
  .catch(err => {
    throw err;
  })
}

export const forgotPasswordSubmit = (email, code, password) => dispatch => {
  return Auth.forgotPasswordSubmit(email, code, password)
  .then(() => {
    dispatch(setUserEmail(email));
  })
  .catch(err => {
    throw err;
  })
};

export const changePasswordSubmit = (email, oldPassword, password) => async dispatch => {

  try {

    dispatch(loaderActions.startAppLoader(LoaderContent.CHANGE_PASSWORD));

    const user = await Auth.signIn(email, oldPassword);

    // Check for the Change Password Challenge
    if(user.challengeName) {
      if(user.challengeName === "NEW_PASSWORD_REQUIRED") {
        const requiredAttributes = {}
        await Auth.completeNewPassword(user, password, requiredAttributes);        
      }
    } else {
      await Auth.changePassword(user, oldPassword, password);
    }

    dispatch(loaderActions.stopAppLoader())
    return await dispatch(handleForceChangePassword(email, false, false));

  } catch(error) {
    dispatch(loaderActions.stopAppLoader())
    throw error;
  }

};

// *****************************************
// Generic Function - TODO - Move it to Utility Functions later
// *****************************************

const getCurrentUTCEpoch = () => {
  var currentDate = new Date();
  var currentUTCDate = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate(), currentDate.getUTCHours(), currentDate.getUTCMinutes(), currentDate.getUTCSeconds());
  var currentUTCEpoch = Math.floor(currentUTCDate.getTime() / 1000);
  return currentUTCEpoch;
};




import React, { useState } from 'react';
import { Link,matchPath } from "react-router-dom";
import { useSelector } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SupportIcon from "@material-ui/icons/ContactSupport";
//import MenuIcon from '@material-ui/icons/Menu';


import { useStyles } from "./styles";
import { adtAutoNavItems, dashboardNavItems, settingsNavItems } from "./content";
import { GlobalRoutes } from "../../GlobalRouter/Routes";

const stateSelector = state => ({
  selectedClinicId: state.clinicReducer.selectedClinicId,
  clinicDashboards: state.clinicReducer.clinicDashboards,
  viewDetails: state.patient360Reducer.viewDetails,
  trialViewList:state.patientTrialReducer.trialViewList || [],
  userRole: state.userReducer.userRole,
  userPermissionsBasedOnClinic: state.userReducer.userPermissionsBasedOnClinic,
});

const LeftMenu = ({ classes }) => {

  const { selectedClinicId, clinicDashboards, viewDetails,trialViewList, userPermissionsBasedOnClinic, userRole } = useSelector(state => stateSelector(state));
  var role;
  var permissions,privileges = [];
  if (userRole[selectedClinicId] !== undefined && userPermissionsBasedOnClinic[selectedClinicId] !== undefined) {
    role = userRole[selectedClinicId].find((_i, index) => index === 0)
    permissions = userPermissionsBasedOnClinic[selectedClinicId][role]
    privileges = [...permissions["READ"],...permissions["WRITE"]];
  }

  const getMenuIndex = () => {
    const indexBasedOnPath = dashboardNavItems.findIndex((e) => { return e.subHeader.filter((she) => { return she.linkTo === window.location.pathname }).length > 0 });
    if (indexBasedOnPath > 0) {
      return indexBasedOnPath;
    }
    return 0;
  }

  const [expanded, setExpanded] = useState(getMenuIndex());
  //const [toggleState, setToggleState] = useState(true);

  const handleChange = panel => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  var triaViewPath = matchPath(window.location.pathname, {path: GlobalRoutes.TRIAL_VIEW.path, exact: true, strict: true});
  const hasPermission = item => !item.privilege || privileges.includes(item.privilege)

  if (window.location.pathname.includes(GlobalRoutes.ADT_AUTOMATION.path) || window.location.pathname.includes(GlobalRoutes.ADT_AUTOMATION_HISTORY.path)) {
    return (
      <div className={classes.leftMenuContainer}>
        <ul className={classes.mainMenuesContainer}>
          <li>
            <span>ADT Automation</span>
            <ul>
              {adtAutoNavItems.map(item => {
                return (
                  <li key={item.label}>
                    <Link className={(window.location.pathname === item.to) ? classes.activeMenu : ''} to={item.to}>{item.label}</Link>
                  </li>
                )
              })}
            </ul>
          </li>
        </ul>
        <div className={classes.helpCenterTextContainer}>
          <SupportIcon />
          <Link to={GlobalRoutes.SETTINGS.path + "/helpcenter"}>Help Center</Link>
        </div>
      </div>
    )
  }

  //window.location.pathname.includes(GlobalRoutes.DASHBOARD.path) || window.location.pathname === GlobalRoutes.LANDING.path
  // TODO: Makke sure that we display only the active menu items
  if (window.location.pathname.includes("/mydashboard")) {

    if (clinicDashboards[selectedClinicId] && selectedClinicId !== "def") {
      const dashboardNavMenus = clinicDashboards[selectedClinicId].category;
      return (
        <div className={classes.leftMenuContainer}>
          <div>
            {dashboardNavMenus.map((item, index) => {
              const permissionExist = permissions["READ"].filter(val => val === item.child_resource.toUpperCase())
              return permissionExist.length > 0 &&
                item.dashboards.filter((i) => i.is_active === true).length > 0 ?
                (
                  <ExpansionPanel
                    expanded={expanded === index}
                    onChange={handleChange(index)}
                    className={classes.expansionPanel}
                    key={index}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
                      <span>{item.name}</span>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <ul className={classes.subMenuesContainer}>
                        {item.dashboards.map(list => (
                          list.is_active ?
                            <li key={list.dashboard_name}>
                              <Link className={(window.location.pathname === "/mydashboard/" + list.dashboard_name.toLowerCase()) || (window.location.pathname === "/mydashboard" && list.display_default === true) ? classes.activeMenu : ''} to={"/mydashboard/" + list.dashboard_name.toLowerCase()}>{list.display_name}</Link>
                            </li> : ""
                        ))}
                      </ul>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ) : ''
            })}

            <div className={classes.helpCenterTextContainer}>
              <SupportIcon />
              <Link to={GlobalRoutes.SETTINGS.path + "/helpcenter"}>Help Center</Link>
            </div>
          </div>
        </div>
      )
    }
    else if (selectedClinicId === "def" && clinicDashboards[selectedClinicId] && permissions !== undefined) {

      const dashboardNavMenus = clinicDashboards[selectedClinicId].category;
      return (
        <div className={classes.leftMenuContainer}>

          {dashboardNavMenus.map((item, index) => {
            const permissionExist = permissions["READ"].filter(val => val === item.child_resource.toUpperCase())
            return permissionExist.length > 0 &&
              item.dashboards.filter((i) => i.is_active === true).length > 0 ?
              (
                <ExpansionPanel
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                  className={classes.expansionPanel}
                  key={index}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
                    <span>{item.name}</span>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <ul className={classes.subMenuesContainer}>
                      {item.dashboards.map(list => (
                        list.is_active ?
                          <li key={list.dashboard_name}>
                            <Link className={(window.location.pathname === "/mydashboard/" + list.dashboard_name.toLowerCase()) || (window.location.pathname === "/mydashboard" && list.display_default === true) ? classes.activeMenu : ''} to={"/mydashboard/" + list.dashboard_name.toLowerCase()}>{list.display_name}</Link>
                          </li> : ""
                      ))}
                    </ul>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ) : ''
          })}

          <div className={classes.helpCenterTextContainer}>
            <SupportIcon />
            <Link to={GlobalRoutes.SETTINGS.path + "/helpcenter"}>Help Center</Link>
          </div>

        </div>
      )

    }
    else {
      return (
        <div className={classes.leftMenuContainer}>
          <div className={classes.helpCenterTextContainer}>
            <SupportIcon />
            <Link to={GlobalRoutes.SETTINGS.path + "/helpcenter"}>Help Center</Link>
          </div>
        </div>
      )
    }

  }

  // // TODO: Following Section is for Older version, can be deleted once new approach is finalized
  // if(window.location.pathname.includes("/mydashboard_tbd")){
  //   return (
  //     <div className={classes.leftMenuContainer}>
  //       <div>
  //       {dashboardNavItems.map((item, index) => {
  //         return (
  //           <ExpansionPanel
  //             expanded={expanded === index}
  //             onChange={handleChange(index)}
  //             className={classes.expansionPanel}
  //             key={index}
  //           >
  //           <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
  //             <span>{item.headerTitle}</span>
  //           </ExpansionPanelSummary>
  //           <ExpansionPanelDetails>
  //             <ul className={classes.subMenuesContainer}>
  //               {item.subHeader.map(list => (
  //                 <li key={list.label}>
  //                   <Link className={(window.location.pathname === list.linkTo) || (window.location.pathname === GlobalRoutes.DASHBOARD.path && list.default === true) ? classes.activeMenu : ''} to={list.linkTo}>{list.label}</Link>
  //                 </li>
  //               ))}
  //             </ul>
  //           </ExpansionPanelDetails>
  //         </ExpansionPanel>
  //       )
  //       })}
  //       <div className={classes.helpCenterTextContainer}>
  //         <SupportIcon />
  //         <Link to={GlobalRoutes.SETTINGS.path + "/helpcenter"}>Help Center</Link>
  //       </div>
  //       </div>
  //     </div>
  //   )
  // }

  if (window.location.pathname.includes(GlobalRoutes.SETTINGS.path)) {
    return (
      <div className={classes.leftMenuContainer}>
        <ul className={classes.mainMenuesContainer}>
          {settingsNavItems.map(item => {
            return (
              <li key={item.headerTitle}>
                <span>{item.headerTitle}</span>
                <ul className={classes.subMenuesContainer}>
                  {item.subHeader.map(list => (
                    hasPermission(list)?<li key={list.label}>
                      <Link className={(window.location.pathname === list.linkTo) || (list.activeLinks && list.activeLinks.includes(window.location.pathname)) || (window.location.pathname === GlobalRoutes.SETTINGS.path && list.default === true) ? classes.activeMenu : ''} to={list.linkTo}>{list.label}</Link>
                    </li>:''
                  ))}
                </ul>
              </li>
            )
          })}
        </ul>
        <div className={classes.helpCenterTextContainer}>
          <SupportIcon />
          <Link to={GlobalRoutes.SETTINGS.path + "/helpcenter"}>Help Center</Link>
        </div>
      </div>
    )
  }

  if (window.location.pathname.includes("/patient360")) {

    if (viewDetails !== undefined && viewDetails.length !== 0) {
      const patient360NavMenus = viewDetails;
      return (
        <div className={classes.leftMenuContainer}>
          <ul className={classes.mainMenuesContainer}>
            <li>
              <Link to="/patient360" default={true} className={(window.location.pathname === "/patient360") ? classes.activeMenu : ''}><i className="fas fa-notes-medical" /> Master View</Link>
            </li>
            {
              patient360NavMenus !== undefined ?
                patient360NavMenus.map(item => (
                  <li key={item.view_id}>
                    <Link className={(window.location.pathname === "/patient360/" + item.view_id) ? classes.activeMenu : ''} to={"/patient360/" + item.view_id}>{item.view_name}</Link>
                  </li>
                )) : ""
            }
          </ul>
          <div className={classes.helpCenterTextContainer}>
            <SupportIcon />
            <Link to={GlobalRoutes.SETTINGS.path}>Help Center</Link>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className={classes.leftMenuContainer}>
          <ul className={classes.mainMenuesContainer}>
            <li>
              <Link className={(window.location.pathname === "/patient360" || viewDetails.length === 0) ? classes.activeMenu : ''} to={GlobalRoutes.PATIENT360.path + "/patient360"}><i className="fa fa-notes-medical" /> Master View</Link>
            </li>
          </ul>
          <div className={classes.helpCenterTextContainer}>
            <SupportIcon />
            <Link to={GlobalRoutes.SETTINGS.path + "/helpcenter"}>Help Center</Link>
          </div>
        </div>
      )
    }
  }
  if (window.location.pathname.includes("/patienttrial")) {
    const selViewId = triaViewPath?parseInt(triaViewPath.params.viewId):trialViewList.length>0?trialViewList[0].view_id:null;
      return (
        <div className={classes.leftMenuContainer}>
          <ul className={classes.mainMenuesContainer}>
            {
              trialViewList.length>0?trialViewList.map(item => (
                  <li key={item.view_id}>
                    <Link className={(selViewId === item.view_id) ? classes.activeMenu : ''} to={"/patienttrial/" + item.view_id}>{item.view_name}</Link>
                  </li>
                )):''
                //<li><Link className={(window.location.pathname === "/patienttrial") ? classes.activeMenu : ''} to={"/patienttrial"}><i className="fa fa-notes-medical" /> Master View</Link></li>
            }
          </ul>
          <div className={classes.helpCenterTextContainer}>
            <SupportIcon />
            <Link to={GlobalRoutes.SETTINGS.path}>Help Center</Link>
          </div>
        </div>
      )
  }

  return null;
}

export default withStyles(useStyles)(LeftMenu);
import {helpCenterActions} from '../actionCreators';

const IntitialState = {
    supportRequestDetails : [],
    requestDetails : [],
    totalPages:0,
}

const helpCenterReducer = (state = IntitialState, action) => {
    switch (action.type) {
        case helpCenterActions.UPDATE_SUPPORT_REQUEST_DETAILS : {
            return {...state, supportRequestDetails : action.payload}
        }
        case helpCenterActions.UPDATE_REQUEST_CATEGORY : {
            return {...state, requestDetails : action.payload}
        }
        case helpCenterActions.UPDATE_TOTAL_PAGES : {
            return {...state, totalPages : action.payload}
        }
        default:{
            return state;
        }
    }
}

export default helpCenterReducer;
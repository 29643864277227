import React, {useState} from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import PropTypes from "prop-types";

import List from "../../../common/List";
import { useStyles } from "./styles";
import NavbarLink from "./NavbarLink";
import { navbarItems } from "./constant";
import { MenuItem } from "@material-ui/core";
import Menu from '@material-ui/core/Menu';

import {GlobalRoutes} from "../../../../GlobalRouter/Routes";



const NavigationBar = props => {
  const { clinicId,clinicDashboards,userRole,userPermissions } = useSelector(state => ({
    clinicId:state.clinicReducer.selectedClinicId,
    clinicDashboards: state.clinicReducer.clinicDashboards,
    userRole: state.userReducer.userRole,
    userPermissions: state.userReducer.userPermissionsBasedOnClinic,
  }));
  var role;
  var permissions = [];
  if (userRole[clinicId] && userPermissions[clinicId]) {
    userRole[clinicId].forEach((role, i) => {
      permissions = [...permissions,...userPermissions[clinicId][role]["READ"],...userPermissions[clinicId][role]["WRITE"]]
    });
  }
  const { headerColor } = props;
  const classes = useStyles(props);

    const [anchorE1, setAnchorE1] = useState(null);
    const open = Boolean(anchorE1);

      const handleMenu = (event) => {
        setAnchorE1(event.currentTarget);
      };

      const handleClose = () => {
        setAnchorE1(null);
      };

  const isActive = navbarItem => {
    if(window.location.pathname.includes(navbarItem.activeLinks[0])) {
      return true;
    }
    return false;
  }

  const isHidden = navbarItem => navbarItem.isHidden?navbarItem.isHidden({...props,clinicDashboards,clinicId,permissions}):false;
  const hasPermission = item => !item.privilege || permissions.includes(item.privilege)

  return (
    <nav className={classes.navigationLinks} >
      <List display="inline" className={classes.navlist}>
        {navbarItems.map(navbarItem => {
          if (navbarItem.type === "link") {
            return (
              isHidden(navbarItem)?'':<ListItem key={navbarItem.label} children={<NavbarLink {...navbarItem} headerColor={headerColor}  />} />
            );
          }
          if (navbarItem.type === "select"){
            return(
              <React.Fragment key={navbarItem.label}>
              <div className={classes.serviceSelectorContainer}>
              <div className={isActive(navbarItem)?classes.navLinkActive: classes.navlist} onClick={handleMenu}>
                Settings
              </div>
              <Menu
                id="menu-appbar"
                anchorEl={anchorE1}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                {navbarItem.services.map(item => (
                  !hasPermission(item)?'':<MenuItem key={item.label}><Link className={(window.location.pathname === item.to) ? classes.activeMenu : classes.menuItem} key={item.label} to={item.to} onClick={handleClose}>{item.label}</Link></MenuItem>
                ))}
              </Menu>
            </div>
            </React.Fragment>
            )
          }
          return null;
        })}
      </List>

    </nav>
  );
};

NavigationBar.propTypes = {
  navbarItems: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      activeLinks: PropTypes.arrayOf(PropTypes.string),
      label: PropTypes.string,
      openInNewTab: PropTypes.bool,
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ),
  headerColor: PropTypes.string,
};

export default NavigationBar;

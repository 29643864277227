export const useStyles = MUITheme => ({
  registrationHeaderWrapper: {
    padding: '0 125px',
    background: MUITheme.palette.background.primary,
    [MUITheme.breakpoints.down("md")]: { padding: '0 25px' },
  },
  logoContainer: {
    "& h1": {
      margin: 0,
      lineHeight: 0,
     '& a':{ textDecoration: 'none' },
      "& img": { width: 180 },
    },
  },
});
  
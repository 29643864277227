import React from "react";
import { Link, useHistory } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";


import {GlobalRoutes} from "../../../GlobalRouter/Routes";
import logo from "../../../assets/images/logo.png";
import NHCButton from "../../common/Button";
import { useStyles } from "./styles";

const RegistrationHeader =  ({classes})  => {

  const history = useHistory();

  const handleSignUpClick = () => {
    history.push(GlobalRoutes.SIGNUP.path);
  }

  const handleLoginClick = () => {
    history.push(GlobalRoutes.LOGIN.path);
  }

  return (
    <Grid container className={classes.plainHeaderContainer}>
      <Grid item xs={12} sm={3} md={6} lg={6} className={classes.logoContainer}>
        <h1>
          <Link to="/">
            <img src={logo} alt="Niram Healthcare" />
          </Link>
        </h1>
      </Grid>
      <Grid item xs={12} sm={9} md={6} lg={6} align="right" className={classes.btnContainer}>
        <NHCButton children="sign up" color="primary" variant="contained" onClick={handleSignUpClick} />
        <NHCButton children="login" color="primary" variant="text" onClick={handleLoginClick} />
      </Grid>
    </Grid>
  );
};

export default withStyles(useStyles)(RegistrationHeader);

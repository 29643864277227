import React, { Component,} from "react";
//import React, { Component, lazy, Suspense } from "react";
//import { connect } from "react-redux";
import { Provider as ReactReduxProvider } from "react-redux";
import Amplify from "aws-amplify";
import {Helmet} from "react-helmet";

import { ThemeProvider as MUIThemeProvider } from "@material-ui/core/styles";

import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import { aws_config } from "./config/aws_config";
import theme from "./assets/Theme";
import GlobalRouter from "./GlobalRouter";
import GlobalLoader from "./components/GlobalLoader";
import metaDetails from "./components/common/MetaDetails";

import store from "./Redux/Store";

// Configure AWS Amplify
Amplify.configure(aws_config);

// Configurae Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

class App extends Component {
  
  // componentDidMount = () => {
  //   this.props.fetchUserDetails();
  // };

  // pageNotFoundAction = () => {
  //   history.push(Routes.LANDING);
  // };

  render() {

    // const { isInitialized } = this.props;

    // if (!isInitialized) {
    //   return <CircularProgress />;
    // }

    return(
      <ReactReduxProvider store={store}>
        <MUIThemeProvider theme={theme}>
          {/* <DefaultHelmet /> */}
            <Helmet>
                <meta charSet="utf-8" />
                <title>{metaDetails.home.metaTitle}</title>
                <meta name="description" content={metaDetails.home.metaDesc}/>
                <meta name="keywords" content="Operational Analytics, Quality Analytics, Revenue Analytics, Closing Care Gaps, Effective Patient Engagement, Population Health, Revenue Cycle Management, Healthcare RPA , Clinic Efficiency, Care Management, Close Care Gaps"/>
            </Helmet>
          <GlobalRouter />
          <GlobalLoader />
        </MUIThemeProvider>
      </ReactReduxProvider>
    );
  }

}

export default App;
const metaDetails = {
    'home':{
       'metaTitle': "Healthcare Automation & Analytics | iNaira Healthcare",
       'metaDesc': "Offers powerful solutions in EMR process automation that significantly reduce preparation times for provider offices",
    },
    'login':{
       'metaTitle': "Healthcare Automation & Analytics | iNaira Healthcare - Login",
       'metaDesc': "Validate registered users by accepting user credentials",
    },
    'signup':{
       'metaTitle': "Healthcare Automation & Analytics | iNaira Healthcare - Signup",
       'metaDesc': " Register a new user to access the health care services",
    }
}
export default metaDetails
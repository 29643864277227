import React, { Fragment } from "react";

import WhiteBgLogo from "../Header/WhiteBgLogo";

const withWhiteBgLogo = (Component) => {
  return props => (
    <Fragment>
      <WhiteBgLogo />
      <Component {...props} />
    </Fragment>
  );
};

export default withWhiteBgLogo;

import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { useStyles } from "./styles";

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

const NavbarLink = props => {
  const { activeLinks, label, openInNewTab, to, type } = props;
  const classes = useStyles(props);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const isActive = (_unused, { pathname }) => {
    //return activeLinks.includes(pathname);
    return (!!activeLinks && activeLinks.some(l=>pathname.includes(l)));
  };

  return (
    type==="menu"?
    <nav>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {label}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem containerElement={
          <NavLink
            children={label}
            to={to}
            isActive={isActive}
            target={openInNewTab ? "_blank" : ""}
            rel={openInNewTab ? "noreferrer noopener" : ""}
            className={classes.navLink}
            activeClassName={classes.navLinkActive}
          />
        }>
          ADT Automation
        </MenuItem>
        <MenuItem onClick={handleClose}>Service 2</MenuItem>
        <MenuItem onClick={handleClose}>Service 3</MenuItem>
      </Menu>
    </nav>
    :
    <NavLink
      children={label}
      to={to}
      isActive={isActive}
      target={openInNewTab ? "_blank" : ""}
      rel={openInNewTab ? "noreferrer noopener" : ""}
      className={classes.navLink}
      activeClassName={classes.navLinkActive}
    />
  );
};

NavbarLink.propTypes = {
  activeLinks: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  openInNewTab: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default NavbarLink;

import { API } from "aws-amplify";

import { APIEndpoints, APIPaths } from "../../config/APIEndpoints"
import { fetchAuthenticatedUser } from "../actionCreators/UserActions/loginActions";
import { initializeAPIOptions } from "../../utility/API"

import { loaderActions, errorActions } from ".";
import { LoaderContent } from "../../utility/constants/LoaderContent";

export const UPDATE_SERVICE_SUBSCRIPTIONS = "UPDATE_SERVICE_SUBSCRIPTIONS";
export const UPDATE_SERVICES = "UPDATE_SERVICES";
export const ADD_SERVICE_SUBSCRIBED = "ADD_SERVICE_SUBSCRIBED";

export const UPDATE_ANNOUNCEMENTS = "UPDATE_ANNOUNCEMENTS";
export const UPDATE_ANNOUNCEMENTS_VISIBILITY = "UPDATE_ANNOUNCEMENTS_VISIBILITY";

export const setServices = services => ({
    type: UPDATE_SERVICES,
    payload: services,
})

export const updateServiceSubscriptions = serviceSubscriptions => ({
    type: UPDATE_SERVICE_SUBSCRIPTIONS,
    payload: serviceSubscriptions,
})

export const addNewServiceSubscribed = service => ({
    type: ADD_SERVICE_SUBSCRIBED,
    payload: service,
})

export const updateAnnouncements = announcements => ({
    type: UPDATE_ANNOUNCEMENTS,
    payload: announcements,
})

export const updateAnnouncementsVisibility = displayStatus => ({
    type: UPDATE_ANNOUNCEMENTS_VISIBILITY,
    payload: displayStatus,
})

const updateErrorCode = err => dispatch => {
  dispatch(errorActions.resetCustomAuthenticationErrorCode(err));
};

//******** fetch all services

const fetchServicesAPI = () => async dispatch => {
    const { token } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.NHCAPI.name;
    const apiPath = APIPaths.PLATFORM_SERVICES;
    const apiOptions = initializeAPIOptions(token);
    return await API.get(apiName, apiPath, apiOptions);
}

export const fetchServices = () => async dispatch =>{
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.PLATFORM_CORE_SERVICES));
        const response = await dispatch(fetchServicesAPI());
        if(response.error.code) {
            throw response.error
        }
        dispatch(setServices(response.data));
        dispatch(loaderActions.stopAppLoader())
    } catch (error) {
        dispatch(loaderActions.stopAppLoader())
        const services=[];
        dispatch(setServices(services));
        throw error;
    }
}

//********* fetch subscribed services */

const fetchSubscribedServicesApi = (clinicId) => async dispatch =>{
    const { token } = await dispatch(fetchAuthenticatedUser());
    const additionalHeaders = {
      "clinic-id": clinicId,
    }
    const apiName = APIEndpoints.NHCAPI.name;
    const apiPath = APIPaths.PLATFORM_SERVICES_SUBSCRIBED;
    const body=undefined;
    // const queryString ={'clinic-id':clinicId};
    // const headers= undefined;
    const apiOptions = initializeAPIOptions(token, body, undefined, additionalHeaders);
    return await API.get(apiName, apiPath, apiOptions);
}

export const fetchSubscribedServices = (clinicId) => async dispatch =>{
    try{
            dispatch(updateErrorCode(undefined));
            const response = await dispatch(fetchSubscribedServicesApi(clinicId));
            // TODO - Need to handle error appropriately
            // if(response.error.code){
            //     throw response.error;
            // }
            dispatch(updateServiceSubscriptions(response.data));
    }catch(error) {
        const services=[];
        dispatch(setServices(services));
        if(error.response.status === 403){
            dispatch(updateErrorCode(403));
        }
    }
}

//******** Update subscribed services */

const updateServiceSubscriptionApi = (clinicId, serviceSubscriptions) => async dispatch =>{
    const { token } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.NHCAPI.name;
    const apiPath = APIPaths.PLATFORM_SERVICES_SUBSCRIBED;
    const additionalHeaders = {
      "clinic-id": clinicId,
    }
    const body = {serviceSubscriptions};
    const apiOptions = initializeAPIOptions(token, body, undefined, additionalHeaders);
    return await API.post(apiName, apiPath, apiOptions);
}


export const updateServiceSubscriptionDetails = (clinicId, serviceSubscriptions) => async dispatch =>{
    try{
        dispatch(loaderActions.startAppLoader(LoaderContent.UPDATE_PLATFORM_CORE_SUBSCRIBED_SERVICES));
        //const response = 
        await dispatch(updateServiceSubscriptionApi(clinicId,serviceSubscriptions));
        // TODO - Need to handle appropriately
        // if(response.error.code) {
        //     throw response.error
        // }
        dispatch(loaderActions.stopAppLoader());
    }
    catch(error){
        dispatch(loaderActions.stopAppLoader());
        throw error
    }
}

//********* add new platform service */

const fetchAddNewPlatformServiceApi = (selectedClinicId, serviceDetails) => async dispatch =>{
    const { token } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.NHCAPI.name;
    const apiPath = APIPaths.ADD_NEW_PLATFORM_SERVICE;
    const additionalHeaders = {
      "clinic-id": selectedClinicId,
    }
    const body = {
        service_name: serviceDetails.service_name,
        service_description: serviceDetails.service_description,
        is_active: serviceDetails.is_active,
        display_order: serviceDetails.display_order
    };
    const apiOptions = initializeAPIOptions(token, body, undefined, additionalHeaders);
    return await API.post(apiName, apiPath, apiOptions);
}

export const addNewPlatformService = (selectedClinicId, serviceDetails) => async dispatch =>{
    try{
        dispatch(loaderActions.startAppLoader(LoaderContent.ADD_NEW_PLATFORM_CORE_SERVICE));
        await dispatch(fetchAddNewPlatformServiceApi(selectedClinicId, serviceDetails));
        dispatch(fetchServices(selectedClinicId));
        dispatch(loaderActions.startAppLoader());
    }catch(_e){
        dispatch(loaderActions.stopAppLoader());
    }
}

//********* update the details of existing platform service */

const fetchUpdatePlatformServiceDetailsApi = (selectedClinicId, serviceDetails) => async dispatch=>{
    const { token } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.NHCAPI.name;
    const apiPath = APIPaths.UPDATE_PLATFORM_SERVICE_DETAILS(serviceDetails.service_id);
    const additionalHeaders = {
      "clinic-id": selectedClinicId,
    }
    const body = {
        service_name: serviceDetails.service_name,
        service_description: serviceDetails.service_description,
        is_active: serviceDetails.is_active,
        display_order: serviceDetails.display_order
    };
    const apiOptions = initializeAPIOptions(token, body, undefined, additionalHeaders);
    return await API.put(apiName, apiPath, apiOptions);
}

export const updatePlatformServiceDetails = (selectedClinicId,serviceDetails) => async dispatch =>{
    try{
        dispatch(loaderActions.startAppLoader(LoaderContent.UPDATE_PLATFORM_CORE_SERVICE));
        await dispatch(fetchUpdatePlatformServiceDetailsApi(selectedClinicId, serviceDetails));
        dispatch(fetchServices(selectedClinicId));
        dispatch(loaderActions.stopAppLoader());
    }catch(_e){
        dispatch(loaderActions.stopAppLoader());
    }
}

//********* fetch Announcements */

const fetchAnnouncementsApi = async () => {

    const url = `${APIEndpoints.NHCAPI.endpoint}${APIPaths.ANNOUNCEMENT}`;
    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
    const response = await fetch(url, {method: "get", headers});
    return response.json();
    
}

export const fetchAnnouncements = () => async dispatch => {
    try{
        const response = await fetchAnnouncementsApi();
        dispatch(updateAnnouncements(response.data));
    }catch(error) {
        dispatch(updateAnnouncements([]));
        throw error;
    }
    
}
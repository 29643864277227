import GraphConstants from '../../components/Dashboard/GraphConstant';

const InitialRequestDetails={
  boxes:[
    {id:1,title:"Care Gaps",chart:GraphConstants.cardChartData2, options:GraphConstants.cardChartOpts2,type:1, backgroundColor:"#4fc3f7"},
    {id:2,title:"New Patients",chart:GraphConstants.mainChart2,options:"",type:1,backgroundColor:""},
    {id:3,title:"No Show Patients",chart:GraphConstants.cardChartData4,options:GraphConstants.cardChartOpts4,type:3,backgroundColor:"#e57373"},
    {id:4,title:"Chronic Patients", chart:GraphConstants.pie1,options:"",type:2,backgroundColor:"#ffebee"},
    {id:5,title:"Frequent Flyers", chart:GraphConstants.mainChart4,options:GraphConstants.cardChartOpts5,type:3,backgroundColor:""},
    {id:6,title:"Claims Fixed", chart:GraphConstants.pie2,options:"",type:2,backgroundColor:"#ffebee"}],
}
const personalizedReducer = (state = InitialRequestDetails, action) => {
  switch (action.type) {
    case  "UPDATE_PERSONALIZED_DETAILS": {
      return {...state, personalizedDetails:{
          ...state.personalizedDetails,...action.payLoad,
      }};
    } 
    default:{
      return state
    }
  } 
} 

export default personalizedReducer;
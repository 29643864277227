import { createMuiTheme } from "@material-ui/core/styles";

export const MUITheme = createMuiTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    text: {
      primary: "#3E3C48",
      inputLabel: '#8E93A2',
      red: "#ED1C24",
      black: '#222026',
      bgBlue: '#132870',
      highRiskRed: '#CF3C32',
      yellow:'#fcb040',
      lightGreen: '#00c853',
      links: '#C5E6F4',
      icon: '#BEC2CD',
      white: {
        primary: '#fff',
        secondary: '#F3f3f2',
        tertiary: '#E8F4FE',
      },
      blue:{
        primary: '#1983D9',
        secondary: '#259BEB',
        tertiary: '#136BD6'
      }, 
    },
    background: {
      gradient: 'radial-gradient(circle, #259AEA 0.04%, #136BD6 100%)',
      primary: '#E8F4FE',
      secondary: '#D1EAFE',
      darkBlue: '#132870',
      lightBlue: '#F3F9FE',
      btnBg: '#259BEB',
      white: '#fff',
      grey: '#F3F3F2'
    },
    border: {
      primary: "#8E93A2",
      secondary: "#979797",
      tertiary: '#EFF3F9',
      white: '#fff',
      blue: '#136BD6',
      grey: '#F3F3F2'
    },
  },
  typography: {
    fontFamily:{
      primary: "Nunito Sans",
      secondary: "Open Sans"
    },
    h2:{
      fontFamily: "Nunito Sans",
      fontSize: 32,
      fontWeight: 600,
      lineHeight: '32px'
    },
    h3:{
      color: '#3E3C48',
      fontFamily: "Nunito Sans",
      fontSize: 26,
      lineHeight: '32px'
    },
    h4:{
      color: '#3E3C48',
      fontFamily: "Nunito Sans",
      fontSize: 24,
      lineHeight: '32px'
    },
    body1:{
      color: '#8E93A2',
      fontFamily: "Open Sans",
      fontSize: 14,
      lineHeight: '21px'
    }
  }, 
});

export default MUITheme;

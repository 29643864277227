export const useStyles = MUITheme => ({
  Userdetails: {
    padding: "15px 20px",
    display: "flex",
    marginBottom: 10,
    /*"& svg": {
      borderWidth: 3,
      borderStyle: 'solid',
      borderColor: '#FFFF',
      borderRadius: 25,
      boxShadow: '0 0 10px 0 rgba(207,217,223,0.5)',
      color: '#3F6677',
      fontSize: 47
    }, */
    "& div": {
      marginLeft: 22,
      "& h4": {
        fontWeight: 600,
        margin: 0,
        color: MUITheme.palette.text.darkGrey,
        lineHeight: "27px",
        fontSize: 20,
      },
      "& a": {
        color: MUITheme.palette.text.lightGrey,
        fontSize: 16,
        lineHeight: "22px",
        textDecoration: "none",
        "&:hover": {
          color: MUITheme.palette.primary.main,
          fontweight: 600,
        },
      },
    },
  },
  avatar: {
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: '#FFFF',
    marginLeft: '0 !important',
    boxShadow: '0 0 10px 0 rgba(207,217,223,0.5)',
    color: '#FFF',
    fontSize: 47,
    width: 53,
    height: 53,
  },
  closeIcon: {
    position: "absolute",
    cursor: "pointer",
    top: 10,
    right: 20,
    display: "none",
    "@media(max-width: 768px)": { display: "block" },
  },
  reactBlockies: {
    borderRadius: 30,
  },
});

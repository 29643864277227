export const useStyles = MUITheme => ({
	footerContainer:{
		padding: '2px 13px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[MUITheme.breakpoints.down("sm")]: {
			padding: '15px 0',
			flexDirection: 'column',
		}
	},
	versionDetails:{
		display: 'flex',
		alignItems: 'center',
		'& svg':{
			paddingRight: 16,
			color: '#CF3C32'
		},
		'& p':{
			margin: 0,
			color: MUITheme.palette.text.blue.tertiary,
			fontFamily: MUITheme.typography.fontFamily.secondary,
			fontSize: 12,
			fontWeight: 'bold',
			letterSpacing: .43,
			lineHeight: '12px',
			'& span':{ 
				display: 'block',
				fontWeight: 'normal',
			}
		}
	},
	footerDescription:{
		margin: 0,
		color: MUITheme.palette.text.inputLabel,
		fontFamily: MUITheme.typography.fontFamily.secondary,
		fontSize: 14,
		lineHeight: '24px',
		[MUITheme.breakpoints.down("sm")]: { padding: '10px 0' }
	},
	footerLogo:{
		width: 104,
		lineHeight: 0,
		textAlign: 'center',
		'& span':{
			color: MUITheme.palette.text.blue.tertiary,
			fontFamily: MUITheme.typography.fontFamily.secondary,
			fontSize: 10,
			fontWeight: 600,
			lineHeight: '15px',
			textTransform: 'uppercase'
		},
		'& h1':{
			margin: 0,
			fontSize: 0,
			'& img':{ width: '100%' }
		}
	}
})
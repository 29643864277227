import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
//import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";

import UserProfilePopUp from "./UserProfilePopUp";
import { useStyles } from "./styles";
import {clinicActions} from "../../../Redux/actionCreators"

import { GlobalRoutes } from "../../../GlobalRouter/Routes";

const LoggedInActions = ({ classes }) => {

  const dispatch = useDispatch();
  const userClinics = useSelector(state => state.clinicReducer.userClinics);
  const selectedClinicId = useSelector(state => state.clinicReducer.selectedClinicId);
  const profilePicURL = useSelector(state => state.userReducer.profilePicURL);
  const clinicDashboards = useSelector(state => state.clinicReducer.clinicDashboards);


  const [showProfilePopup, setShowProfilePopup] = useState(false);

  const handleProfileIconClick = () => {
    setShowProfilePopup(!showProfilePopup);
  };

  const handleClinicChange = (event) => {
    const clinicId = event.target.value
    dispatch(clinicActions.updateUserSelectedClinicId(clinicId));
    if(!clinicDashboards[clinicId] && ![GlobalRoutes.DASHBOARD.path,GlobalRoutes.PATIENTTRIAL.path].includes(window.location.pathname)){
      dispatch(clinicActions.fetchDashboards(clinicId));
    }
  }

  return (
    <div className={classes.loggedInActionsContainer}>
      <div className={classes.clinicSelectorContainer}>
        <Select
          id="clinic"
          value={selectedClinicId}
          onChange={handleClinicChange}
          displayEmpty
        >
        <MenuItem className={classes.disabledMenuItem} value="" disabled>Clinic Selector</MenuItem>
          {userClinics.map((item, index) => (
            <MenuItem key={index} value={item.clinic_id}>{item.name}</MenuItem>
          ))}  
        </Select>   
      </div>
      <div className={classes.iconsContainer}>
        <NotificationsNoneIcon className={classes.NotificationsIcon} />
        {/* <AccountCircleIcon onClick={handleProfileIconClick} className={classes.AccountCircleIcon} /> */}
        <Avatar
              className={classes.AccountCircleIcon}
              src={profilePicURL}
              onClick={handleProfileIconClick}
            />
        <UserProfilePopUp show={showProfilePopup} handleClose={() => setShowProfilePopup(false)} />
      </div>
    </div>
  );
};

export default withStyles(useStyles)(LoggedInActions);

import { API } from "aws-amplify";

import { APIEndpoints, APIPaths } from "../../config/APIEndpoints";
import { initializeAPIOptions } from "../../utility/API";
import { fetchAuthenticatedUser } from "./UserActions/loginActions";

import {loginActions} from "./UserActions"
import { loaderActions } from "./";
import { LoaderContent } from "../../utility/constants/LoaderContent";

export const UPDATE_LEAD_GEN_EMRS = "UPDATE_LEAD_GEN_EMRS";
export const UPDATE_LEAD_GEN_SERVICES = "UPDATE_LEAD_GEN_SERVICES";
export const UPDATE_LEAD_GEN_CLINIC_INFO = "UPDATE_LEAD_GEN_CLINIC_INFO";
export const UPDATE_LEAD_GEN_CONTACT_INFO = " UPDATE_LEAD_GEN_CONTACT_INFO";


export const updateEMRs = (emrs) => dispatch => {
    dispatch({type: UPDATE_LEAD_GEN_EMRS, payload: emrs});
}

export const updateServices = (services) => dispatch => {
    dispatch({type: UPDATE_LEAD_GEN_SERVICES, payload: services});
}

export const updateClinicInfo = (practiceName, numOfClinics, typeOfClinic) => dispatch => {

    const clinicInfo = {
        practiceName, 
        numOfClinics, 
        typeOfClinic
    }

    dispatch({type: UPDATE_LEAD_GEN_CLINIC_INFO, payload: clinicInfo});
}

export const updateContactInfo = (address, city, state, zipCode) => dispatch =>{
    const contact= {
        address,
        city,
        state,
        zipCode
    }
    dispatch({type: UPDATE_LEAD_GEN_CONTACT_INFO, payload: contact});
}
const saveLeadGenerationRequestAPI = (email, phoneNumber, clinicInfo, contact, emrs, services) => async dispatch => {
  
    const { token } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.NHCAPI.name;
    const apiPath = APIPaths.LEAD_GENERATION;

    const body = { 
        email, 
        phone_number: phoneNumber, 
        clinic_info: {
            no_of_clinics: clinicInfo.numOfClinics,
            type_of_clinic: clinicInfo.typeOfClinic.join(","),
            practice_name: clinicInfo.practiceName
        }, 
        contact,
        emrs, 
        services,
    };

    const apiOptions = initializeAPIOptions(token, body);
    return await API.post(apiName, apiPath, apiOptions);

  };
  
export const saveLeadGenerationRequest = (email, phoneNumber, clinicInfo, contact, emrs, services) => async dispatch => {

    // call the backend APIs to update in the database
    try {

        dispatch(loaderActions.startAppLoader(LoaderContent.LEAD_GENERATION));

        const { error } = await dispatch(saveLeadGenerationRequestAPI(email, phoneNumber, clinicInfo, contact, emrs, services));
        if (error) {
          throw error;
        }

        // Update User Attribute IsFirstLogin attribute
        dispatch(loginActions.updateFirstTimeUserAttribute("0"));
        dispatch(loaderActions.stopAppLoader())

    } catch(_error) {
        dispatch(loaderActions.stopAppLoader())
    }
}


/*****************************************
 * Contact US API Call
 *****************************************/


const saveContactUsAPI = async (name, email, phoneNumber, organization, state, description) => {
    const url = `${APIEndpoints.NHCAPI.endpoint}${APIPaths.CONTACT_US}`;
    const payload = {
        name, 
        email, 
        phoneNumber, 
        organization, 
        state, 
        description
    }
    // const headers = {
    //     "Accept": "application/json",
    //     "Content-Type": "application/json"
    // }
    const response = await fetch(url, {method: "post", body: JSON.stringify(payload)});
    return response.json();
  };

  export const saveContactUs = (name, email, phoneNumber, organization, state, description) => async dispatch => {
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.CONTACT_US));
        const { error } = await saveContactUsAPI(name, email, phoneNumber, organization, state, description);
        if (error) {
          throw error;
        }
        dispatch(loaderActions.stopAppLoader());

    } catch(error) {
        dispatch(loaderActions.stopAppLoader());
        throw error;
    }
}



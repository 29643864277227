import React, { Fragment } from "react";

import Footer from "../Footer";
import LeftMenuAndHeader from '../LeftMenuAndHeader/';

const WithHeaderAndFooter = Component => {

  return props => (
      <Fragment>
        <LeftMenuAndHeader >
          <Component {...props}/>
        </LeftMenuAndHeader>
        <Footer />
      </Fragment>
  );

};

export default WithHeaderAndFooter;

export const useStyles = MUITheme => ({
  leftMenuContainer: { 
    width: 216,
    minHeight: '100vh',
    padding: '15px 0 15px',
    position: 'relative',
    background: MUITheme.palette.background.gradient,
    '& ul':{
      width: '100%',
      padding: 0,
      margin: 0,
      '& li':{ 
        listStyle: 'none',
        '& a':{
          boxSizing: 'border-box',
          width: '100%',
          padding: '0 10px 0 28px',
          display: 'inline-block',
          color: MUITheme.palette.text.links,
          fontFamily: MUITheme.typography.fontFamily.primary,
          fontSize: 14,
          lineHeight: '38px',
          textDecoration: 'none',
          '&:hover':{ background: MUITheme.palette.background.darkBlue }
        }
      },
      
    },
    '& .MuiExpansionPanel-root.Mui-expanded':{ 
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      margin: 0
    },
    '& .MuiExpansionPanel-root.Mui-expanded:before':{ opacity: 1 },
    [MUITheme.breakpoints.down("sm")]: { display: "none", },
  },
  mainMenuesContainer:{
    '& > li':{
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'rgba(19, 107, 214, .5)',
      '& > span':{
        padding: '0 24px 10px',
        marginTop: 16,
        display: 'inline-block',
        color: MUITheme.palette.text.black,
        fontFamily: MUITheme.typography.fontFamily.primary,
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '18px',
      }
    }
  },
  subMenuesContainer:{
    '& li':{
      '& > a':{
        boxSizing: 'border-box',
        width: '100%',
        display: 'inline-block',
        fontFamily: MUITheme.typography.fontFamily.primary,
        fontSize: 14,
        textDecoration: 'none',
        padding: '0 9px 0 32px',
        color: MUITheme.palette.text.links,
        lineHeight: '48px',
        '&:hover':{ background: MUITheme.palette.background.darkBlue }
      }
    }
  },
  activeMenu: {
    background: MUITheme.palette.background.darkBlue,
    fontWeight: 600,
  },

  root: {
    flexGrow: 1,
    '& .MuiListItemIcon-root':{ color: MUITheme.palette.text.links },
    '& .MuiSvgIcon-root':{ color: MUITheme.palette.text.links },
    '& .MuiListItem-root':{
      '&:hover':{
        background: MUITheme.palette.background.darkBlue,
        '& .MuiListItemIcon-root':{            
          color: MUITheme.palette.background.secondary,
          fontWeight: 'bold'
        }
      }
    }
  },
  userProfileContainer: { textAlign: 'left' },
  nested: { paddingLeft: MUITheme.spacing(4)
  },
  userDetailsContainer: {
    padding: MUITheme.spacing(3),
    marginLeft: "0px",
    color: MUITheme.palette.text.secondary,
    borderRadius:"4px",
    "& h4": {
      fontWeight: "300",
      marginBottom:"20px",
    }, 
  },

  userHistoryContainer: {
      marginLeft: '40px',
      color: MUITheme.palette.text.secondary,
      paddingBottom:"40px",
      "& h4": {
        color: MUITheme.palette.text.black,
        fontWeight: "300",
        marginBottom:"20px",
        },   
  },
  UserText:{
    borderBottom:"1px solid #EDF0F2",
     clear: "both",
     display: "table",
     width:"100%",
  },
  leftText:{
    float:"left",
  },
  rightText:{
    float:"right"
  },
  dataContainer: {
      display: "inline-block",
      marginLeft:"20px",
      "& .MuiInputBase-root":{
        lineHeight:"27px",
        paddingBottom: "6px",
       fontSize:"20px",
        color: MUITheme.palette.text.primary
      },
      "& .MuiInput-underline":{
        "&::before":{
          borderBottom:"1px solid #E2F3FA",
        },
      },
  },
  AdminWraper:{
    width:"100%",
    paddingTop:"0px",
    display:"flex",
    minHeight:"100vh",
  },
  AdminLeftmenu:{
    background: MUITheme.palette.background.gradient,
    transition:" all 0.4s ease-in-out 0s",
    "& .MuiTypography-body1":{
      fontSize:"16px",
      color: MUITheme.palette.text.links,
    },
    "@media (max-width:600px)": {
      position: "absolute",

  
    },
  },
  AdminUser:{
    flex:"1",
    padding:"40px",
    position: 'relative',
    width: "90%",
    [MUITheme.breakpoints.down("sm")]: {width:"90%"},
    
  },
  ProfileBG:{
    border:"3px solid #F8C8D5",
    width:"130px",
    height:"130px",
    borderRadius:"100px",
    textAlign:"center",
    verticalAlign:" middle",
    display:"table-cell",
    marginTop:"25px",  
    "& img":{
      width:"80px",
      height:"100px",
      textAlign:"center",
    },   
  },
  firstName:{
    width:"50%",
    padding: "8px 0px",
    fongtSize:"14px",   
  },
  email:{ padding: "8px 0px" },
  phoneNumber:{ padding: "8px 0px" },
  Address:{
    width:"100%",
    padding: "8px 0px",
    borderRight:"0px",
    borderLeft:"0px",
    borderTop:"0px",
    outline:"none",
 
    "&::focus":{
      outline:"none",
    },
  },

  editbtn:{
    marginTop:"20px",
    float:"right",
    "& button":{
      border: "1px solid #CD5F79",
      borderRadius: "6px",
      padding:"13px 48px 11px;"
    },
  },
  logoContainer:{
    marginTop:"14px",
    margin:"auto",
    textAlign:"center",
    "& img":{
      width:"72%",
      marginLeft:"15px",
      "@media (max-width:960px)": {
        display:"none",
      },
    },
  },
  AdminShortLeftMenu:{
    flex:"1",
    maxWidth:"70px",
    background: "#6dc9ea",
    minHeight:"100%",
    flexShrink: "0",
    whiteSpace: "nowrap",
    overflowX:"hidden",
    marginTop:"-60px",
    transition:" all 0.4s ease-in-out 0s",
    [MUITheme.breakpoints.down("sm")]: {maxWidth:"200px", },
    "@media (max-width:600px)": {
      maxWidth:" 200px",
      position: "absolute",
      marginTop:" 0px",
      zIndex:"999"  
    },  
     "& .MuiTypography-body1":{
      fontSize:"16px",
      color:"#000",
    },
  },
 listItem:{ background: 'red' },
 expansionPanel: {
  background: 'none',
  boxShadow:'none',
  '& .MuiExpansionPanelSummary-content': { 
    margin: 0,
    '& span':{
      padding: '0 15px',
      color: MUITheme.palette.text.black,
      fontFamily: MUITheme.typography.fontFamily.secondary,
      fontSize: 14,
      fontWeight: '600',
      lineHeight: '18px'
    },
  },
  '& .MuiExpansionPanelSummary-root':{ padding: 0 },
  '& .MuiExpansionPanelSummary-root.Mui-expanded':{ minHeight: 48 },
  '& .MuiExpansionPanelSummary-content.Mui-expanded':{ margin: 0 },
  '& .MuiExpansionPanelSummary-expandIcon':{
    padding: 0,
    position: 'absolute',
    top: '50%',
    right: 20,
    transform: 'translateY(-50%)',
    '& svg':{ color: '#222026' }
  },
  '& .MuiExpansionPanelDetails-root':{ padding: 0 },
  '& .MuiIconButton-label':{ padding: 0 }
 },
 helpCenterTextContainer: {
  width: '100%',
  padding: '15px 0px',
  borderTop: '1px solid #fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  bottom: 0,
  '& svg': { 
    paddingRight: 5,
    color: MUITheme.palette.text.white.primary
  },
  '& a':{
    color: MUITheme.palette.text.white.primary,
    fontSize: 14,
    letterSpacing: 0.5,
    lineHeight: '19px',
    textAlign: 'center',
    textDecoration: 'none'
  }
 },
 toggleBtn:{
   padding:"10px 20px",
   color: MUITheme.palette.text.white.primary,
   cursor: 'pointer',
   '& selected':{
     color:MUITheme.palette.text.black
   }
 },

});
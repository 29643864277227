export const useStyles = MUITheme => ({
  NotificationBar: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)',
  },
  notificationText: {
    padding: "4px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: 'relative', 
    "& span": {
      fontSize: 12,
      lineHeight: "21px",
      fontFamily: MUITheme.typography.fontFamily.secondary
    },
    '& > div':{
      display: 'flex',
      alignItems: 'center'
    }
  },
  WARNING: {
    backgroundColor: '#FFF2E0',
    color: '#635543',
    "& svg": {
      marginRight: 8,
      color: '#635543',
      fontSize: 12
    },
  },
  INFORMATION: {
    // backgroundColor: theme.palette.text.informationBarBg,
    color: MUITheme.palette.text.white,
    "& svg": { marginRight: 21 },
  },
  linkContainer:{
    position: 'absolute',
    right: 19,
    '& label':{ 
      paddingRight: 5,
      color: '#8E93A2',
      fontSize: 12,
      lineHeight: '13px',
      textDecoration: 'none',
    },
    '& svg':{ color: '#8E93A2' },
  }
});

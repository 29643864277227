
import {patient360Actions} from '../actionCreators';

const IntitialState = {
    patientBasicDetails : [],
    otherDetails : [],
    singlePatientDetails: {'patient_account_number': "", 
                            'race': undefined, 
                            'ethnicity': undefined, 
                            'home_phone': undefined, 
                            'work_phone': undefined, 
                            'email_id': undefined, 
                            'address': undefined, 
                            'no_show_prediction': null, 
                            'claims_rejected': null, 
                            'gaps_pending': [], 
                            'gaps_closed': [],
                            'chronic_data': [], 
                            'last_5_visits': []
                        },
    metrics:{
            'high_risk_patients': 0,
            'medium_risk_patients': 0,
            'low_risk_patients': 0,
            'total_patients':0
        },
    totalCount:"",
    paginator:0,
    filterValue: {
        'age_group': [], 
        'pcp': [], 
        'insurance': [], 
        'location': []
    },
    viewDetails: [],
    filters: {
        "risk_key": "",
        'age_group': "",
        'gender': "",
        'pcp': "",
        'insurance': "",
        'location': "",
        'min_no_show_prob': "",
        'max_no_show_prob': "",
        },
    file: {'count': 0, 'url': ""},
}

//Merge patient details based on patient account number

const updatePatientDetails = (data, value) =>{
    if(data !== undefined && data.length !== 0){
        const res = data.map((item)=>{
            return {
                ...item, 
                ...(value.find((itmInner) => itmInner.patient_account_number === item.patient_account_number))
              }
         })
        return res;
    }
    else if(value === undefined && value.length !==0){
        return data;
    }
    else{
        return value;
    }
}

const patient360Reducer = (state = IntitialState, action) =>{
    switch(action.type){
        case patient360Actions.UPDATE_BASIC_PATIENT_DETAILS_LIST : {
            return{...state, patientBasicDetails:action.payload}
        }
        case patient360Actions.UPDATE_OTHER_DETAILS : {
            return{...state, otherDetails: action.payload}
        }
        case patient360Actions.UPDATE_ONE_PATIENT_DETAILS : {
            return{...state, singlePatientDetails: action.payload}
        }
        case patient360Actions.UPDATE_METRICS_VALUE : {
            return{...state, metrics: action.payload}
        }
        case patient360Actions.UPDATE_PAGINATOR : {
            return{...state, paginator: action.payload}
        }
        case patient360Actions.UPDATE_FILTER_COLUMNS : {
            return{...state, filterValue: action.payload}
        }
        case patient360Actions.UPDATE_FILTER_OBJECT : {
            return{...state, filters: action.payload}
        }
        case patient360Actions.UPDATE_PATIENT_DETAILS : {
            return{
                ...state, patientBasicDetails: updatePatientDetails(state.patientBasicDetails, action.payload)
            }
        }
        case patient360Actions.UPDTAE_BASIC_INFO : {
            return{
                ...state, patientBasicDetails: updatePatientDetails(state.patientBasicDetails, action.payload)
            }
        }
        case patient360Actions.UPDATE_VIEW_LIST:{
            return{...state, viewDetails: action.payload}
        }
        case patient360Actions.UPDATE_FILE_CONTENT:{
            return{...state, file: action.payload}
        }
        case patient360Actions.UPDATE_CUURENT_COUNT:{
            return{...state, totalCount: action.payload}
        }
        default : {
            return state;
        }
    }

}
export default patient360Reducer;
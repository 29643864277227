import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MoodIcon from "@material-ui/icons/Mood";
import SettingsIcon from '@material-ui/icons/Settings';
import { Modal } from "@material-ui/core";

import { useStyles } from "./styles";
import UserProfileCard from "../../../common/UserProfileCard";
import { loginActions } from "../../../../Redux/actionCreators/UserActions";
import {GlobalRoutes} from "../../../../GlobalRouter/Routes";

const UserProfilePopUp = ({ classes, show, handleClose }) => {

  const history = useHistory();

  const { firstName, profilePicURL} = useSelector(state => ({
    firstName: state.userReducer.firstName,
    profilePicURL: state.userReducer.profilePicURL,
  }));
  const dispatch = useDispatch();

  const handleSignout = () => {
    dispatch(loginActions.signout);
    history.push(GlobalRoutes.HOME.path);
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <div className={classes.UserProfilePopUpContainer}>
        <UserProfileCard profilePicURL={profilePicURL} firstName={firstName} />
        <ul className={classes.userProfileMenuList}>
          <li>
            <MoodIcon />
            <Link to={GlobalRoutes.USER_PROFILE.path}><span>My Profile</span></Link>
          </li>
          <li>
            <SettingsIcon />
            <Link to={GlobalRoutes.SETTINGS.path}><span>Settings</span></Link>
          </li>
          <li>
            <ExitToAppIcon />
            <span onClick={handleSignout}>Sign Out</span>
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default withStyles(useStyles)(UserProfilePopUp);

import { API } from "aws-amplify";

import { APIEndpoints, APIPaths } from "../../config/APIEndpoints";
import { initializeAPIOptions } from "../../utility/API";
import { fetchAuthenticatedUser } from "./UserActions/loginActions";

import { loaderActions } from ".";
import { LoaderContent } from "../../utility/constants/LoaderContent";

export const UPDATE_SUPPORT_REQUEST_DETAILS = "UPDATE_SUPPORT_REQUEST_DETAILS";
export const UPDATE_REQUEST_CATEGORY = "UPDATE_REQUEST_CATEGORY";
export const UPDATE_TOTAL_PAGES = "UPDATE_TOTAL_PAGES";


export const addSupportRequestDetails = (supportDetails) => dispatch =>{
    dispatch({
        type: UPDATE_SUPPORT_REQUEST_DETAILS,
        payload: supportDetails
    })
}

export const addCategoryDetails = (categoryDetails) => dispatch =>{
    dispatch({
        type: UPDATE_REQUEST_CATEGORY,
        payload: categoryDetails
    })
}

export const updateTotalPages = (totalPages) => dispatch =>{
    dispatch({
        type: UPDATE_TOTAL_PAGES,
        payload: totalPages
    })
}


//******************** Fetch Request category */

const fetchRequestCategoryApi = () => async dispatch =>{
    const { token } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.NHCAPI.name;
    const apiPath = APIPaths.SUPPORT_CATEGORY;
    const apiOptions = initializeAPIOptions(token, undefined, undefined, undefined);

    return await API.get(apiName, apiPath, apiOptions);
}

export const fetchRequestCategory = () => async dispatch =>{
    try{
        const response = await dispatch(fetchRequestCategoryApi());
        dispatch(addCategoryDetails(response.data));
    }catch(error){
        throw error;
    }
}

//******************* List of support requests */

const fetchSupportRequestApi = (filterStatus, currentPage, key) => async dispatch =>{
    const { token } = await dispatch(fetchAuthenticatedUser());
    const queryStringParameters = {
        'status': filterStatus,
        'page': currentPage,
        key,
    }
    const apiName = APIEndpoints.NHCAPI.name;
    const apiPath = APIPaths.SUPPORT_REQUEST_LIST;
    const apiOptions = initializeAPIOptions(token, undefined, queryStringParameters, undefined);
    return await API.get(apiName, apiPath, apiOptions);
}

export const fetchSupportRequest = (status, currentPage, key) => async dispatch =>{
    try{
        dispatch(loaderActions.startAppLoader(LoaderContent.SUPPORT_REQUEST_DETAILS));
        var response = []
        if(status !== "All"){
            response = await dispatch(fetchSupportRequestApi(status, currentPage, key));
        }
        else{
            response = await dispatch(fetchSupportRequestApi("", currentPage, key));
        }
        dispatch(addSupportRequestDetails(response.data.content))
        dispatch(updateTotalPages(response.data.total_pages));
        dispatch(loaderActions.stopAppLoader());
    }catch(error){
        dispatch(loaderActions.stopAppLoader());
        const array = [];
        dispatch(addSupportRequestDetails(array));
        dispatch(updateTotalPages(0));
        throw error
    }
}


//***************************** Submit support request */
const submitSupportRequestApi = (categoryId, title, details, file) => async dispatch =>{
    const { token } = await dispatch(fetchAuthenticatedUser());
    const body={
        categoryId,
        title,
        details,
        file
    }
    const apiName = APIEndpoints.NHCAPI.name;
    const apiPath = APIPaths.SUPPORT_REQUEST_LIST;
    const apiOptions = initializeAPIOptions(token, body, undefined, undefined);
    return await API.post(apiName, apiPath, apiOptions);
    
}

export const submitSupportRequest = (categoryId, title, details, file) => async dispatch =>{
    try{
        
        dispatch(loaderActions.startAppLoader(LoaderContent.SUBMIT_REQUEST));
        await dispatch( submitSupportRequestApi(categoryId, title, details, file));

        // Refresh the Support Requests
        dispatch(fetchSupportRequest("", 1, ""));
        dispatch(loaderActions.stopAppLoader());

    }catch(error){
        dispatch(loaderActions.stopAppLoader());
        throw error;
    }
}
import {nlpActions} from "../actionCreators";

const InitialChartData = {
    chartData : {},
    text: "",
    chart_heading: "",
}

const nlpReducer = (state = InitialChartData, action) => {
    switch (action.type) {
        case nlpActions.UPDATE_CHART_DATA: {
            return { ...state, chartData: action.payload };
        }
        case nlpActions.UPDATE_SEARCH_TEXT: {
            return{...state, text: action.payload}
        }
        case nlpActions.UPDATE_CHART_HEADING: {
            return{...state, chart_heading: action.payload}
        }
        default: {
            return state;
        }
        
    }
}

export default nlpReducer;
export const useStyles = MUITheme => ({
	versionDetailsContainer:{
    '& > span':{
    	padding: '13px 0',
    	display: 'inline-block',
    	color: '#136BD6',
		  fontFamily: MUITheme.typography.fontFamily.primary,
		  fontWeight: 600,
		  fontSize: 14,
		  letterSpacing: 0.5,
		  lineHeight: '19px',
    }    
	},
	versionDetails:{
		display: 'flex',
		alignItems: 'center',
		'& svg':{
			paddingRight: 16,
			color: '#CF3C32'
		},
		'& p':{
			margin: 0,
			color: MUITheme.palette.text.blue.tertiary,
			fontFamily: MUITheme.typography.fontFamily.secondary,
			fontSize: 12,
			fontWeight: 'bold',
			letterSpacing: .43,
			lineHeight: '12px',
			'& span':{ 
				display: 'block',
				fontWeight: 'normal',
			}
		}
	}
})
import {adtActions} from '../actionCreators';

const InitialPatientDetails={
    patientDetails:[],
    patientFollowUpDetails: [],
    filesProcessed: [],
}

const adtReducer=(state=InitialPatientDetails, action)=>{
    switch(action.type){
        case adtActions.UPDATE_PATIENT_DETAILS : {
            return {...state, patientDetails : action.payload }
        }
        case adtActions.UPDATE_PATIENT_FOLLOWUP_DETAILS: {
            return {...state, patientFollowUpDetails : action.payload }
        }
        case adtActions.FILES_PROCESSED: {
            return {...state, filesProcessed : action.payload }
        }
        default:{
            return state
        }
    }
}

export default adtReducer;
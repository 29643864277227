import React, { Suspense } from "react";
import { BrowserRouter as ReactRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";

import { setupRouteAuthentications } from "./Routes";
import { loginActions } from "../Redux/actionCreators/UserActions";
import PrivateRoute from "../components/PrivateRoute";
import PageNotFound from "../components/common/PageNotFound";

class GlobalRouter extends React.Component {
  componentDidMount() {
    this.props.initApp();
  }

  render() {
    if (!this.props.isInitialized) {
      return <LinearProgress />;
    }

    const routes = setupRouteAuthentications();

    return (
      <ReactRouter>
        <Suspense fallback={<LinearProgress />}>
          <Switch>
            <Route path="/" exact component={routes.HOME.component} />
            {Object.values(routes).map((route, index) => {
              if (route.redirectTo) {
                return (
                  <PrivateRoute
                    key={route + index}
                    path={route.path}
                    component={route.component}
                    isAllowed={route.isAllowed}
                    redirectTo={route.redirectTo}
                  />
                );
              }
              return <Route key={index} path={route.path} component={route.component} />;
            })}
            <Route component={PageNotFound} />
          </Switch>
        </Suspense>
      </ReactRouter>
    );
  }
}

const mapStateToProps = state => ({
  isInitialized: state.userReducer.isInitialized,
  isLoggedIn: state.userReducer.isLoggedIn,
});

const mapDispatchToProps = dispatch => ({
  initApp: () => dispatch(loginActions.initializeApplication),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalRouter);

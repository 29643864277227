export const navbarItems = [
  { label: "Dashboards", type: "link", openInNewTab: false, activeLinks: ["/mydashboard","/patienttrial"], to: "/mydashboard" },
  // { label: "Services", type: "select", activeLinks: ["/adtautomation"], services:[
  // 	{label: "ADT Automation", to:"/adtautomation"}
  // ] },
  { label: "Patient 360", type: "link", privilege:"PATIENT-360",openInNewTab: false, activeLinks: ["/patient360"], to: "/patient360",isHidden:({clinicId,clinicDashboards,permissions})=> !permissions.includes("PATIENT-360") || (clinicDashboards.init && !!clinicDashboards[clinicId] && (!clinicDashboards[clinicId].category || !clinicDashboards[clinicId].category.length))},
  /* { label: "Patient Trial", type: "link", openInNewTab: false, activeLinks: ["/patienttrial"], to: "/patienttrial" }, */
  { label: "Settings", type: "select", activeLinks: ["/settings"], services:[
  	{label: "Service Subscription", privilege:"SERVICE SUBSCRIPTION",to:"/settings"},
    {label: "Payers And Measures", privilege:"PAYER MEASURE", to:"/settings/payersandmeasures"},
    {label: "Clinic Management", privilege:"CLINIC MANAGEMENT", to:"/settings/clinicmanagement"},
    {label: "Customize App", privilege:"APP CUSTOMIZATION", to:"/settings/customizeapp"},
    {label: "User Management", privilege:"USER MANAGEMENT", to:"/settings/usermanagement"},
    {label: "User Role Management", privilege:"ROLE MANAGEMENT", to:"/settings/userrolemanagement"},
    {label: "Data Import", privilege:"DATA", to:"/settings/dataimport"},
    {label: "Help Center", to:"/settings/helpcenter"},
  ] },
  // { label: "Analytics", type: "link", openInNewTab: false, activeLinks: ["/myanalytics"], to: "/myanalytics" },
  // { label: "Services", type: "link", openInNewTab: false, activeLinks: ["/", "/adtservice"], to: "/adtservice" },
  // { label: "Subscription", type: "link", openInNewTab: false, activeLinks: ["/subscription"], to: "/subscription" },

];

const GraphConstant={
// Card Chart 2
    cardChartData2 :{
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
        {
            label:"A Metric Term",
            data:[2100,2500,3500,3100,3000,3900,2000],
            fill:false,
            borderColor: '#eceff1',
            animationEnabled: true,
        },
        ],
    },
    
    cardChartOpts2 :{
        tooltips: {
        enabled: false,
        custom: 'CustomTooltips'
        },
        maintainAspectRatio: false,
        legend: {
        display: false,
        },
        scales: {
        xAxes: [
            {
            gridLines: {
                color: 'transparent',
                zeroLineColor: 'transparent',
            },
            ticks: {
                fontSize: 2,
                fontColor: 'transparent',
            },
    
            }],
        yAxes: [
            {
            display: false,
            ticks: {
                display: false,
               // min: Math.min.apply(Math, cardChartData2.datasets[0].data) - 5,
               // max: Math.max.apply(Math, cardChartData2.datasets[0].data) + 5,
            },
            }],
        },
        elements: {
            line: {
            tension: 0.00001,
            borderWidth: 1,
            },
            point: {
            radius: 4,
            hitRadius: 10,
            hoverRadius: 4,
            },
        },
        },

mainChart2:{
        labels:["Week1","Week2","Week3"],
        datasets:[
            {
                label:"A Metric Term",
                data:[2100,5001,9000],
                borderColor: "#ff1744",
                fill:false,
                animationEnabled:true
    
            }
        ]
    },
    
// Card Chart 4
cardChartData4 :{
    labels: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    datasets: [
      {
        label: 'My First dataset',
        borderColor: 'transparent',
        data: [78, 81, 80, 45, 34, 12, 40, 75, 34, 89, 32, 68, 54, 72, 18, 98],
      },
    ],
  },
  
  cardChartOpts4 :{
    tooltips: {
      enabled: false,
      custom: "CustomTooltips"
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: false,
          barPercentage: 0.6,
        }],
      yAxes: [
        {
          display: false,
        }],
    },
  },
  
  
mainChart3:{
        labels:["Week1","Week2","Week3"],
        datasets:[
            {
                label:"A Metric Term",
                data:[4560,5001,20000],
                borderColor: "#9c27b0",
                fill:false,
                animationEnabled:true
    
            }
        ]
    },
    
mainChart4:{
      labels: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      datasets:[
          {
              label:"A Metric Term",
              data: [78, 81, 80, 45, 34, 12, 40, 75, 34, 89, 32, 68, 54, 72, 18, 98],
              borderColor: "transparent",
              fill:false,
              animationEnabled:true
  
          }
      ]
  },

  cardChartOpts5 :{
    tooltips: {
      enabled: false,
      custom: "CustomTooltips"
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: false,
          barPercentage: 0.6,
        }],
      yAxes: [
        {
          display: false,
        }],
    },
  },
  
  
pie1:{
      labels:["Primary Care", "Special Care", "Long time Care", "Emergency Care", "Hospice Care"],
      datasets:[{
        data:[23,11,16,30,30],
        backgroundColor:["#e57373","#f06292", "#ba68c8", "#f50057", "#3f51b5"]
      }
      ]
    },

pie2:{
      labels:["HMO", "PPO", "EPO", "POS", "HDHP"],
      datasets:[{
        data:[23,11,16,30,30],
        backgroundColor:["#e57373","#f06292", "#ba68c8", "#f50057", "#3f51b5"]
      }]
    },
};
export default GraphConstant;